import { useEffect, useState } from "react";

// form validation
import { ErrorMessage, Field } from "formik";

// components
import axiosInstance from "../../../apis/config";
import ProductColor from "./productColor";
import ProductImage from "./productImage";
import style from "../../../pages/dashboard/dashboard.module.css";
import Spinner from "../../common/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ConfirmPopup from "../../common/confirmPopup";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/slices/toastSlice";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ProductForm = ({
  form,
  errors,
  touched,
  values,
  imageError,
  onStockError,
  productName,
  selectedImages,
  onImageInput,
  setFieldValue,
  setSelectedImages,
  documents,
}) => {
  const [categories, setCategories] = useState(null);
  const [brands, setBrands] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axiosInstance
      .get("/brands")
      .then((res) => {
        setBrands(res.data.allData);
      })
      .catch((error) => console.log(error));

    axiosInstance
      .get("/categories")
      .then((res) => {
        setCategories(res.data.allData);
      })
      .catch((error) => console.log(error));
  }, []);
  const dispatch = useDispatch();
  const { id } = useParams();

  const DeleteThisImage = (image) => {
    setLoading(true);
    const token = localStorage.getItem("userToken");
    axiosInstance
      .delete(`/products/delete-image/${id}`, {
        params: {
          image,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((res) => {
        setLoading(false);
        window.location.reload();
        dispatch(showToast("Product was deleted successfully!"));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        dispatch(
          showToast("Failed to delete product! Please try again later!")
        );
      });
    setLoading(false);
  };
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  return (
    <>
      {/* name */}
      <div className="form-group mb-3">
        <label htmlFor={`${form}-prod-name`} className="mb-1">
          Name
        </label>
        <Field
          type="text"
          autoComplete="off"
          name="name"
          id={`${form}-prod-name`}
          className={`form-control ${
            errors.name && touched.name ? "is-invalid" : ""
          }`}
        />
        <ErrorMessage
          name="name"
          component="div"
          className="invalid-feedback"
        />
      </div>

      <div className="form-group mb-3">
        <label htmlFor={`${form}-prod-description`} className="mb-1">
          Description
        </label>
        <ReactQuill
          id={`${form}-prod-description`}
          name="description"
          modules={quillModules}
          formats={quillFormats}
          value={values.description}
          onChange={(value) => setFieldValue("description", value)}
          className={` ${
            errors.description && touched.description ? "is-invalid" : ""
          }`}
        />
        <ErrorMessage
          name="description"
          component="div"
          className="invalid-feedback"
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor={`${form}-prod-box`} className="mb-1">
          What will be in the box
        </label>
        <ReactQuill
          id={`${form}-prod-box`}
          name="box"
          modules={quillModules}
          formats={quillFormats}
          value={values.box}
          onChange={(value) => setFieldValue("box", value)}
          className={` ${errors.box && touched.box ? "is-invalid" : ""}`}
        />
        <ErrorMessage name="box" component="div" className="invalid-feedback" />
      </div>
      <div className="row m-0">
        {/* price */}
        <div className="col-6 px-0">
          <div className="form-group mb-3">
            <label htmlFor={`${form}-prod-price`} className="mb-1">
              Price
            </label>
            <Field
              type="number"
              name="price"
              min="1"
              id={`${form}-prod-price`}
              className={`form-control ${
                errors.price && touched.price ? "is-invalid" : ""
              }`}
            />
            <ErrorMessage
              name="price"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
        {/* discount */}
        <div className="col-6 pe-0 ps-md-3">
          <div className="form-group mb-3">
            <label htmlFor={`${form}-prod-discount`} className="mb-1">
              Discount
            </label>
            <Field
              type="number"
              name="discount"
              min="0"
              id={`${form}-prod-discount`}
              className={`form-control ${
                errors.discount && touched.discount ? "is-invalid" : ""
              }`}
            />
            <ErrorMessage
              name="discount"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
      </div>
      <div className="row m-0">
        {/* category */}
        <div className="col-6 px-0">
          <div className="form-group mb-3">
            <label htmlFor={`${form}-prod-category`} className="mb-1">
              Category
            </label>
            <Field
              name="category"
              as="select"
              id={`${form}-prod-category`}
              className={`form-control text-capitalize ${
                errors.category && touched.category ? "is-invalid" : ""
              }`}
            >
              <option value="">Choose...</option>
              {categories?.map((ele) => (
                <option key={ele._id} value={ele._id}>
                  {ele.name}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="category"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
        {/* brand */}
        <div className="col-6 pe-0 ps-md-3">
          <div className="form-group mb-3">
            <label htmlFor={`${form}-prod-brand`} className="mb-1">
              Brand
            </label>
            <Field
              name="brand"
              as="select"
              id={`${form}-prod-brand`}
              className={`form-control ${
                errors.brand && touched.brand ? "is-invalid" : ""
              }`}
            >
              <option value="">Choose...</option>
              {brands?.map((ele) => (
                <option key={ele._id} value={ele._id}>
                  {ele.name}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="brand"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
      </div>
      {/* stock and colors */}
      <div className="form-group mb-3">
        <label htmlFor={`${form}-prod-colors`} className="mb-1">
          Colors & Stock & Size
        </label>
        <div
          className={`d-flex gap-3 flex-wrap ${
            values.colors?.length > 0 ? "mb-3 mt-2" : ""
          }`}
        >
          {values.colors?.map((color) => (
            <ProductColor
              key={color.color}
              color={color}
              onStockError={onStockError}
              onUpdateStock={(value) => {
                const colorIndx = values.colors.findIndex(
                  (ele) => ele.color === color.color
                );
                const newColors = [...values.colors];
                newColors[colorIndx].stock = value;
                setFieldValue("colors", newColors);
              }}
              onUpdateSize={(value) => {
                const colorIndx = values.colors.findIndex(
                  (ele) => ele.color === color.color
                );
                const newColors = [...values.colors];
                newColors[colorIndx].size = value;
                setFieldValue("colors", newColors);
              }}
              onDelete={() =>
                setFieldValue(
                  "colors",
                  values.colors.filter((ele) => ele.color !== color.color)
                )
              }
            />
          ))}
        </div>
        <Field
          type="color"
          name="colors"
          id={`${form}-prod-colors`}
          className={`form-control ${
            errors.colors && touched.colors ? "is-invalid" : ""
          }`}
          value={selectedColor}
          onChange={(e) => setSelectedColor(e.target.value)}
          onBlur={(e) => {
            if (
              values.colors.findIndex((ele) => ele.color === e.target.value) !==
              -1
            )
              return;
            setFieldValue("colors", [
              ...values.colors,
              { color: e.target.value, stock: 1 },
            ]);
          }}
        />
        <ErrorMessage
          name="colors"
          component="div"
          className="invalid-feedback"
        />
      </div>
      {/* current images */}
      {form === "update" && (
        <>
          <label htmlFor={`${form}-prod-images`} className="mb-1">
            current images
          </label>
          <br />
          <div
            className={`d-flex align-items-center gap-3 flex-wrap mb-3 mt-2`}
          >
            {documents.map((ele) => (
              <>
                <div className="position-relative">
                  <>
                    <img
                      key={ele._id}
                      src={ele.url}
                      alt={ele.url}
                      className={`${style["dash-prod-form-img"]} img-fluid border`}
                    />
                    {loading ? (
                      <p className="text-danger btn p-0 outline-0 border-0 position-absolute top-0 end-0">
                        <Spinner />
                      </p>
                    ) : (
                      <button
                        type="button"
                        className="text-danger btn p-0 outline-0 border-0 position-absolute top-0 end-0"
                        onClick={() => setShowWarning(true)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    )}
                  </>
                </div>
                {showWarning && (
                  <ConfirmPopup
                    msg={`Are you sure you want to remove image?`}
                    onConfirm={() => {
                      DeleteThisImage(ele.public_id);
                      setShowWarning(false);
                    }}
                    onCancel={() => setShowWarning(false)}
                  />
                )}
              </>
            ))}
          </div>
        </>
      )}

      {/* images */}
      <div className="form-group mb-4 pb-4">
        <label htmlFor={`${form}-prod-images`} className="mb-1">
          Images
        </label>
        <div
          className={`d-flex align-items-center gap-3 flex-wrap ${
            selectedImages?.length > 0 ? "mb-3 mt-2" : ""
          }`}
        >
          {selectedImages?.map((image, index) => (
            <ProductImage
              key={image._id || `${image.name}-${index}`}
              // src={
              //   image.src
              //     ? server + "/" + image.src
              //     : URL.createObjectURL(image)
              // }
              src={image}
              productName={productName}
              onDelete={() => {
                setSelectedImages(
                  selectedImages?.filter((ele) => {
                    return image._id
                      ? ele._id !== image._id
                      : ele.name !== image.name;
                  })
                );
              }}
            />
          ))}
        </div>
        <Field
          type="file"
          name="documents"
          id={`${form}-prod-images`}
          multiple
          className={`form-control ${imageError ? "is-invalid" : ""}`}
          onChange={onImageInput}
        />
        {imageError && <p className="invalid-feedback">{imageError}</p>}
      </div>
    </>
  );
};

export default ProductForm;
