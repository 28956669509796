import { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import PopoverContent from "./popover";

const ScrollToTopBtn = () => {
  const [showButton, setShowButton] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const targetRef = useRef(null);

  const handleFlowClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300 && !showButton) {
        setShowButton(true);
      } else if (window.scrollY <= 300 && !showButton) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowPopover(true);
  }, []);

  useEffect(() => {
    let timer;
    if (showPopover) {
      timer = setTimeout(() => {
        setShowPopover(false);
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showPopover]);
  return (
    <>
      <a
        href="https://wa.me/+254712012113"
        target="_blank"
        rel="noopener noreferrer"
        className={`${
          showButton ? "top-whatsaap" : "bottom-whatsaap"
        } position-fixed justify-content-center align-items-center text-light border-0 rounded-circle cursor-pointer flow-button-whatsaap`}
        ref={targetRef}
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <button
        className="position-fixed justify-content-center align-items-center text-light border-0 rounded-circle cursor-pointer flow-button btn-bg-dark hover-bg-yellow"
        onClick={handleFlowClick}
        style={{ display: showButton ? "block" : "none" }}
      >
        <FontAwesomeIcon icon={faCircleChevronUp} />
      </button>
      <PopoverContent
        show={showPopover}
        targetRef={targetRef}
        message={"Yes! We Can Chat 🥰"}
        place={"top"}
        onClose={() => setShowPopover(false)}
      />
    </>
  );
};

export default ScrollToTopBtn;
