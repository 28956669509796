export default function PageHeader({ path }) {
  return (
    <div className="headerCover d-flex justify-content-center align-items-center">
      <div className="headerContent d-flex flex-column justify-content-center">
        <h1
          className="text-capitalize text-center w-100"
          style={{ color: "#fff", fontFamily: "Poetsen One" }}
        >
          {path}
        </h1>
      </div>
    </div>
  );
}
