import React, { useEffect, useState } from "react";
import "./categories.css";
import axiosInstance from "../../apis/config";
import { Link } from "react-router-dom";

const Categories = () => {
  const [allCategories, setAllCategories] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/categories`, {})
      .then((res) => {
        const shuffledCategories = res.data.data.sort(
          () => Math.random() - 0.5
        );
        setAllCategories(shuffledCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div class="category">
      <div class="container">
        <div class="category-item-container">
          {allCategories &&
            allCategories.map((cat) => (
              <div class="category-item">
                <div class="category-img-box">
                  <img src={cat.image[0].url} alt="dress & frock" width="30" />
                </div>
                <div class="category-content-box">
                  <div class="category-content-flex d-flex flex-wrap">
                    <h5 class="category-item-title">{cat.name}</h5>

                    <p class="category-item-amount mb-0">
                      ({cat.products_id?.length})
                    </p>
                  </div>

                  <Link
                    to={`/shop?page=1&brand=all&category=${cat._id}&sort=0&price=0`}
                    class="category-btn"
                  >
                    Show all
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
