import React from "react";

const StarRating = ({ rating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <ion-icon key={i} name="star" style={{ color: "gold" }}></ion-icon>
      );
    } else {
      stars.push(<ion-icon key={i} name="star-outline"></ion-icon>);
    }
  }

  return <div className="showcase-rating d-flex">{stars}</div>;
};

export default StarRating;
