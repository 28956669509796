import React from "react";
import HomeProductsCard from "./homeProductsCard";

const HomeProductCartShared = ({ products, shop }) => {
  return (
    <div class="product-container">
      <div class="container">
        <div class="product-main">
          <div class="product-grid">
            {products.map((product) => (
              <HomeProductsCard
                key={product._id}
                product={product}
                shop={shop}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProductCartShared;
