import React, { useEffect, useState } from "react";
import "./homeProductDetails.css";
import axiosInstance from "../../apis/config";
import { Link, useNavigate } from "react-router-dom";
import StarRating from "./starRating";
const HomeProductDetails = () => {
  const [products, setProducts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [randomProduct, setRandomProduct] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setShowSpinner(true);
    axiosInstance
      .get("/products", {})
      .then((response) => {
        setProducts(response.data.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (products.length > 0) {
      // If products array is not empty, select a random product
      const randomIndex = Math.floor(Math.random() * products.length);
      setRandomProduct(products[randomIndex]);
    }
  }, [products]);
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const goToProducts = () => {
    navigate(`/product-details/${randomProduct._id}`);
  };

  // console.log("my randomProduct now", randomProduct);
  return (
    <div class="container">
      {showSpinner ? (
        <>
          <h2 class="title m-2 he-headers">
            <span>The deal</span> of the day
          </h2>
          <div class="product-featured border rounded p-5">
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>
            <p class="placeholder-wave">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>{" "}
          </div>
        </>
      ) : (
        <div class="product-featured">
          {randomProduct && (
            <h2 class="title m-2 he-headers">
              <span>The deal</span> of the day
            </h2>
          )}
          {randomProduct && (
            <div class="showcase-wrapper has-scrollbar">
              <div class="showcase-container">
                <div class="showcase">
                  <div class="showcase-banner">
                    <img
                      src={randomProduct.documents[0]?.url}
                      alt={randomProduct.name}
                      class="showcase-img"
                    />
                  </div>

                  <div class="showcase-content">
                    <div class="d-flex" style={{ marginBottom: 0 }}>
                      <p className="mb-0">
                        <StarRating
                          rating={randomProduct.ratings}
                          className="mb-0"
                          style={{ marginBottom: 0 }}
                        />
                      </p>
                      <p style={{ marginLeft: "5px", fontSize: "12px" }}>
                        ({randomProduct.reviews?.length}) reviews
                      </p>
                    </div>

                    <Link to={`/product-details/${randomProduct._id}`}>
                      <h3 class="showcase-title">{randomProduct.name}</h3>
                    </Link>

                    <p
                      class="showcase-desc"
                      dangerouslySetInnerHTML={{
                        __html: randomProduct.description.slice(0, 300) + "...",
                      }}
                    >
                      {/* {randomProduct.description.slice(0, 300)}... */}
                    </p>

                    <div class="price-box">
                      <p class="price">
                        sh. {randomProduct.price.toLocaleString()}
                      </p>

                      <del>
                        sh.{" "}
                        {(
                          (randomProduct.discount / 100) * randomProduct.price +
                          randomProduct.price
                        ).toLocaleString()}
                      </del>
                    </div>

                    <button onClick={() => goToProducts()} class="add-cart-btn">
                      see more
                    </button>

                    <div class="showcase-status">
                      <div class="wrapper">
                        <p>
                          already sold: <b>{getRandomNumber(5, 20)}</b>
                        </p>

                        <p>
                          available: <b>{getRandomNumber(20, 50)}</b>
                        </p>
                      </div>

                      <div class="showcase-status-bar"></div>
                    </div>

                    <div class="countdown-box">
                      <p class="countdown-desc">Hurry Up! Offer ends in:</p>

                      <div class="countdown">
                        <div class="countdown-content">
                          <p class="display-number">{getRandomNumber(1, 7)}</p>

                          <p class="display-text">Days</p>
                        </div>

                        <div class="countdown-content">
                          <p class="display-number">{getRandomNumber(1, 24)}</p>
                          <p class="display-text">Hours</p>
                        </div>

                        <div class="countdown-content">
                          <p class="display-number">{getRandomNumber(1, 60)}</p>
                          <p class="display-text">Min</p>
                        </div>

                        <div class="countdown-content">
                          <p class="display-number">⌛</p>
                          <p class="display-text">Sec</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomeProductDetails;
