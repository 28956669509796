import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
// style
import styles from "./../../pages/home/home.module.css";
// images
import homeSlider1 from "../../assets/home-slider/ninetyone.jpg";
import homeSlider3 from "../../assets/home-slider/ninetytwo.jpg";
import homeSlider2 from "../../assets/home-slider/ninetythree.jpg";
import "./banner.css";

const MainCarousel = () => {
  const HomImgs = [
    {
      id: 1,
      src: homeSlider1,
      content: "Ninety One Online Store is Now Open",
      desc: "Have you heard?",
      color: "#ffff",
    },
    {
      id: 2,
      src: homeSlider2,
      content: "Quick Online Shopping, fast Delivery",
      desc: "Why choose us?",
      color: "#000",
    },
    {
      id: 3,
      src: homeSlider3,
      content: "Secured Methods of Payment",
      desc: "Level of security?",
      color: "#ffff",
    },
  ];

  return (
    <div className="banner">
      <div className="container">
        <div className="slider-container">
          <Carousel
            fade
            className={styles["carousel-indicators"]}
            prevIcon={
              <FaArrowAltCircleLeft className={styles.carouselControl} />
            }
            nextIcon={
              <FaArrowAltCircleRight className={styles.carouselControl} />
            }
          >
            {HomImgs.map((img) => (
              <Carousel.Item
                key={img.id}
                className={`${styles["carousel-item"]}`}
              >
                <img
                  className={`d-block w-100 h-100 ${styles.carouselImage}`}
                  src={img.src}
                  alt={img.content}
                />
                <Carousel.Caption className={`${styles["carousel-caption"]}`}>
                  <div className={`${styles.captionCarouel}`}>
                    <div className="row">
                      <p
                        className={`col-5 col-md-3 col-lg-2 ${styles.discount}`}
                      >
                        {img.desc}
                      </p>
                    </div>
                    <div className="row">
                      <h3
                        className="col-10 col-md-6 col-xl-8 mt-2"
                        style={{
                          color: `${img.color}`,
                          fontFamily: `Poetsen One`,
                        }}
                      >
                        {img.content}
                      </h3>
                    </div>
                    <Link to="/shop" className="banner-btn">
                      Shop now
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default MainCarousel;
