import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import { showCartModal } from "../../store/slices/cartModalSlice";

// functions
import { addItemToCart } from "../../functions/cart";
import StarRating from "../common/starRating";

// style
import style from "../../pages/searchPage/searchPage.module.css";
import { showToast } from "../../store/slices/toastSlice";

const SearchCard = ({ product, query }) => {
  const [shownTitle, setShownTitle] = useState("");
  const [shownCategory, setShownCategory] = useState("");
  const [shownBrand, setShownBrand] = useState("");
  const [inCart, setInCart] = useState(false);
  const [showBtnSpinner, setShowBtnSpinner] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [activeColor, setActiveColor] = useState({});
  const [activeSize, setActiveSize] = useState("");

  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  const highlightMatch = (name, query) => {
    const regex = new RegExp(query, "i");
    const destructuredName = name.split(regex);
    const highlighted = destructuredName.map((piece, indx, arr) => {
      if (indx < arr.length - 1) {
        return (
          <span key={indx}>
            {piece}
            <span
              className={`bg-yellow text-dark ${
                indx === 0 && piece === "" ? "text-capitalize" : ""
              } p-0`}
            >
              {query}
            </span>
          </span>
        );
      }
      return <span key={indx}>{piece}</span>;
    });
    return highlighted;
  };
  const navigate = useNavigate();
  const handleAddToCart = () => {
    if (cart.items) {
      setShowBtnSpinner(true);
    }
    if (product.colors?.length <= 1) {
      addItemToCart(dispatch, cart._id, product._id, activeColor, activeSize);
      dispatch(showToast("Product Added To Cart!"));
    } else {
      navigate(`/product-details/${product._id}`);
      dispatch(showToast("Please Select Color and Size!"));
    }
  };

  useEffect(() => {
    setShownTitle(highlightMatch(product.name, query));
    setShownCategory(
      highlightMatch(
        `${product.category.name[0].toUpperCase()}${product.category.name.slice(
          1
        )}`,
        query
      )
    );
    setShownBrand(highlightMatch(product.brand.name, query));
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    setShowBtnSpinner(false);
    let outOfStock = false;
    // check if product out of stock
    if (product.colors.every((obj) => obj.stock === 0)) {
      setOutOfStock(true);
      outOfStock = true;
    }
    // if not logged in
    if (!cart.items) {
      setInCart(false);
      return;
    } else if (!outOfStock) {
      // set first active color
      const activeColor = product.colors.find((obj) => obj.stock > 0);
      setActiveColor(activeColor.color);
      setActiveSize(activeColor.size);
    }
    const productIndx = cart.items.findIndex(
      (ele) => ele.product_id._id === product._id
    );
    setInCart(productIndx === -1 ? false : true);
  }, [cart, product]);

  const addToRecentlyViewed = (product) => {
    let recentlyViewed = localStorage.getItem("recentlyViewedProducts");
    recentlyViewed = recentlyViewed ? JSON.parse(recentlyViewed) : [];

    const productIndex = recentlyViewed.findIndex(
      (item) => item._id === product._id
    );
    if (productIndex === -1) {
      recentlyViewed.unshift(product);
      if (recentlyViewed.length > 10) {
        recentlyViewed.pop();
      }

      // Update local storage
      localStorage.setItem(
        "recentlyViewedProducts",
        JSON.stringify(recentlyViewed)
      );
    }
  };
  const shareToSocialMedia = (value, imageUrl) => {
    if (navigator.share) {
      const shareData = {
        title: "Check out this product!",
        text: "Product Link: " + value,
        url: value,
      };

      const files = [];
      if (imageUrl) {
        fetch(imageUrl)
          .then((response) => response.arrayBuffer())
          .then((imageBuffer) => {
            const imageFile = new File([imageBuffer], "product_image.jpg", {
              type: "image/jpeg",
            });

            files.push(imageFile);
            shareData.files = files;

            navigator
              .share(shareData)
              .then(() => {
                console.log("Product link and image shared successfully!");
              })
              .catch((error) => {
                console.error("Error sharing:", error);
              });
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            navigator.share(shareData);
          });
      } else {
        navigator.share(shareData);
      }
    } else {
      copyToClipboard(value);
    }
  };
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(showToast("Link Copied!"));
  };
  function getTotalStock(colors) {
    let totalStock = 0;
    for (const color of colors) {
      totalStock += color.stock;
    }
    return totalStock;
  }
  return (
    <>
      <div class="showcase">
        <div class="showcase-banner">
          <Link
            to={`/product-details/${product._id}`}
            onClick={() => addToRecentlyViewed(product)}
          >
            <img
              src={product.documents[0]?.url}
              alt="Mens Winter Leathers Jackets"
              width="300"
              class="product-img default"
              style={{ maxHeight: "200px" }}
            />
            <img
              src={
                product?.documents[1]
                  ? product?.documents[1]?.url
                  : product?.documents[0]?.url
              }
              alt="Mens Winter Leathers Jackets"
              width="300"
              class="product-img hover"
              style={{ maxHeight: "200px" }}
            />
          </Link>
          <p class="showcase-badge">-{product.discount}%</p>

          <div class="showcase-actions">
            <Link to={`/product-details/${product._id}`} class="btn-action">
              <ion-icon name="eye-outline"></ion-icon>
            </Link>
            <button
              class="btn-action"
              onClick={() =>
                shareToSocialMedia(
                  `${`/product-details/${product._id}`}`,
                  product.documents && product.documents[0]?.url
                )
              }
            >
              <ion-icon name="share-outline"></ion-icon>
            </button>
            {!(outOfStock || cart.role === "admin") ? (
              showBtnSpinner ? (
                <button className="btn-action">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              ) : inCart ? (
                <button
                  class="btn-action"
                  onClick={() => dispatch(showCartModal(true))}
                >
                  <ion-icon name="cart"></ion-icon>
                </button>
              ) : (
                <button class="btn-action" onClick={() => handleAddToCart()}>
                  <ion-icon name="cart-outline"></ion-icon>
                </button>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="showcase-content">
          <Link
            to={`/product-details/${product._id}`}
            class="showcase-category"
          >
            {getTotalStock(product.colors)} items
          </Link>

          <Link to={`/product-details/${product._id}`}>
            <h3 class="showcase-title">
              {shownTitle.slice(0, 30)}...{" "}
              <div className={`${style["extra-info"]} mt-1 fw-semibold`}>
                {shownCategory}, {shownBrand}
              </div>
            </h3>
          </Link>

          <div class="showcase-rating">
            <StarRating rating={product.ratings} />
          </div>

          <div class="price-box">
            <p class="price">sh. {product.price.toLocaleString()}</p>
            <del>
              was{" "}
              {(
                (product.discount / 100) * product.price +
                product.price
              ).toLocaleString()}
            </del>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCard;
