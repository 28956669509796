import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// component
import axiosInstance from "../../apis/config";
import SearchCard from "../../components/searchPage/searchCard";
import PagePagination from "../../components/shop/pagePagination";
import Spinner from "../../components/common/spinner";

// style
import style from "./searchPage.module.css";

const SearchPage = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    query: "",
  });
  const searchContent = useRef();

  const getData = (query, page = 1) => {
    setPage(+page);
    axiosInstance
      .get("/products/search", {
        params: {
          search: query,
          page,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
        setTotalResults(res.data.totalResults);
      })
      .catch((error) => console.log(error));
  };

  const handlePageChange = (page) => {
    setData(null);
    setSearchParams({ page, query: searchParams.get("query") });
    searchContent.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setData(null);
    const searchQuery = searchParams.get("query");
    if (searchQuery) {
      getData(searchQuery, searchParams.get("page"));
      setQuery(searchQuery);
    }
  }, [searchParams]);

  return (
    <div id="search-page" className={`${style["search-page"]} py-5`}>
      <div className="container-fluid py-3">
        {data ? (
          data.length > 0 ? (
            <div ref={searchContent}>
              <h1 className="h4 mb-4 px-2">Total Results: {totalResults}</h1>
              <div className="row m-0 row-gap-3 mb-5">
                <div class="product-grid">
                  {data.map((product) => (
                    // <div key={product._id} className="sol-sm-6 col-md-4">
                    <SearchCard product={product} query={query} />
                    // </div>
                  ))}
                </div>
              </div>
              <PagePagination
                totalPages={totalPages}
                currentPage={page}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <>
              <h2 className="text-center h5">
                Sorry, can't find matching for "{query}"
              </h2>
              <div className="text-center">
                <p>Consider the following:</p>
                <ul>
                  <li>**Check your spelling for typing errors</li>
                  <li>**Try searching with short and simple keywords</li>
                  <li>**Try searching more general terms like categories</li>
                </ul>
              </div>
            </>
          )
        ) : query ? (
          <Spinner message={`Bring you the results of ${query}...`} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchPage;
