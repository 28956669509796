// style
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { showToast } from "../../store/slices/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import StarRating from "../common/starRating";
import { BsPerson, BsEnvelope, BsGeoAlt, BsTelephone } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import jwtDecode from "jwt-decode";
import { showLoginModal } from "../../store/slices/loginModalSlice";
import RatingModal from "./adminRatingModal";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../apis/config";
import ConfirmPopup from "../common/confirmPopup";

const FullDescription = ({ description, data }) => {
  const cart = useSelector((state) => state.cart.cart);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const [open, setOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  let token = localStorage.getItem("userToken");

  const dispatch = useDispatch();
  const closeModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    const token = localStorage?.getItem("userToken");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        localStorage.removeItem("userToken");
      } else {
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [cart]);

  const writeReview = () => {
    setLoading(true);
    // setTimeout(() => {
    if (isLoggedIn && cart.role === "user") {
      dispatch(showToast("You Must Order to Write A Review."));
    } else if (isLoggedIn && cart.role === "admin") {
      setOpen(true);
      dispatch(showToast("Admin can write a review"));
    } else {
      dispatch(showLoginModal(true));
      dispatch(showToast("Please Login To Write a Review."));
    }

    setLoading(false);
    // }, 2000);
  };
  const calculateRatingCnt = (reviews) => {
    const ratingCount = [0, 0, 0, 0, 0];
    reviews.forEach((review) => {
      ratingCount[review.rating - 1]++;
    });
    return ratingCount.map((count, index) => ({
      stars: index + 1,
      reviews: count,
    }));
  };

  const ratingCnt = calculateRatingCnt(data.reviews);
  const filteredReviews = selectedRating
    ? data.reviews.filter((review) => review.rating === selectedRating)
    : data.reviews;

  const handleStarClick = (rating) => {
    setSelectedRating(rating);
  };

  const Stars = ({ stars, cnt }) => (
    <>
      {[...Array(stars)].map((_, i) => (
        <span key={i}>
          <ion-icon name="star" style={{ color: "gold" }}></ion-icon>
        </span>
      ))}
      {[...Array(5 - stars)].map((_, i) => (
        <span key={i + stars}>
          <ion-icon name="star-outline"></ion-icon>
        </span>
      ))}

      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
        ({cnt}) reviews
      </span>
    </>
  );
  const [sortingOption, setSortingOption] = useState("good");

  const handleSortingChange = (event) => {
    setSortingOption(event.target.value);
  };

  const sortReviews = (reviews) => {
    switch (sortingOption) {
      case "new":
        return reviews.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "old":
        return reviews.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      case "good":
        return reviews.sort((a, b) => b.rating - a.rating);
      case "bad":
        return reviews.sort((a, b) => a.rating - b.rating);
      default:
        return reviews;
    }
  };

  const sortedReviews = sortReviews(filteredReviews);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 5;

  const totalPages = Math.ceil(sortedReviews.length / reviewsPerPage);

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = sortedReviews.slice(
    indexOfFirstReview,
    indexOfLastReview
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startReviewIndex = indexOfFirstReview + 1;
  const endReviewIndex =
    indexOfLastReview < sortedReviews.length
      ? indexOfLastReview
      : sortedReviews.length;
  const Pagination = () => {
    return (
      <nav className="text-center" aria-label="Page navigation">
        <ul className="pagination-review">
          <li>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-link rounded-start"
            >
              Previous
            </button>
          </li>
          {[...Array(totalPages)].map((_, i) => (
            <li key={i}>
              <button
                onClick={() => paginate(i + 1)}
                className={`pagination-link ${
                  currentPage === i + 1 ? "active" : ""
                }`}
              >
                {i + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-link rounded-end"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };
  const productId = data._id;
  const handleSubmit = (id) => {
    axiosInstance
      .delete(`/products/create-review`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
        data: {
          reviewId: id,
          productId,
        },
      })
      .then((res) => {
        setShowWarning(false);
        dispatch(showToast("Review Deleted successfully!"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <RatingModal
        open={open}
        onClose={closeModal}
        orderId={"adminreviedthisproductskippititisnotnecessaryatall"}
        productId={data._id}
      />

      <div
        className="tabsContainer mt-0 border-top pt-3"
        style={{ fontSize: "14px" }}
      >
        <Tabs
          defaultActiveKey="Description"
          id="uncontrolled-tab-example"
          className="mb-3 Tabs"
          transition={true}
          fill
        >
          <Tab eventKey="Description" title="Description">
            <p
              className={`description px-lg-0 px-md-1 px-sm-4 px-1 pt-1 mx-auto lh-lg color-main-gray mb-0`}
              style={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </Tab>
          <Tab eventKey="Reviews" title="Reviews">
            <div className="container">
              {data.reviews.length === 0 && (
                <div className="no-reviews text-center">
                  <p>There are currently no reviews for this product.</p>
                  <button
                    className="btn btn-primary btn-lg mt-1"
                    style={{ fontSize: "15px" }}
                    onClick={writeReview}
                  >
                    {loading ? "Loading . . ." : "Write a Review"}
                  </button>
                </div>
              )}
              {data.reviews.length >= 1 && (
                <div className="row">
                  <div className="col-sm-4 col-md-3">
                    <div className="hidden-xs">
                      <div className="form-group">
                        <h5 className="my-1" style={{ marginLeft: "3px" }}>
                          Filter Reviews
                        </h5>

                        <ul className="list-group">
                          <li
                            className="list-group-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleStarClick(null)}
                          >
                            <button
                              className="btn"
                              onClick={() => handleStarClick(null)}
                            >
                              Show all ({data.reviews.length})
                            </button>
                          </li>
                          {ratingCnt.reverse().map((rating, index) => (
                            <li key={index} className="list-group-item">
                              <button
                                className={`btn btn-link ${
                                  selectedRating === rating.stars
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleStarClick(rating.stars)}
                              >
                                <Stars
                                  stars={rating.stars}
                                  cnt={rating.reviews}
                                />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block btn-lg mt-1"
                          style={{ fontSize: "15px" }}
                          onClick={writeReview}
                        >
                          {loading ? "Loading . . ." : "Write a Review"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-9">
                    <form name="form" method="post" action="#">
                      <div className="row">
                        <div className="col-sm-6 col-sm-push-6 col-md-4 col-md-push-8 my-1">
                          <select
                            className="form-control"
                            value={sortingOption}
                            onChange={handleSortingChange}
                          >
                            <option value="new">Sort: newest to oldest</option>
                            <option value="old">Sort: oldest to newest</option>
                            <option value="good">Sort: best to worst</option>
                            <option value="bad">Sort: worst to best</option>
                          </select>
                        </div>
                        <div className="col-sm-6 col-sm-pull-6 col-md-8 col-md-pull-4">
                          <h5
                            className="mt-2"
                            style={{ fontSize: "12px", marginLeft: "4px" }}
                          >
                            Showing {startReviewIndex} - {endReviewIndex} of{" "}
                            {sortedReviews.length} reviews
                          </h5>
                        </div>
                      </div>
                    </form>
                    {currentReviews.map((review, index) => (
                      <div key={index} className="review">
                        <div className="border rounded my-1">
                          <div>
                            <div
                              className="col-sm-3 my-2"
                              style={{ marginLeft: "10px" }}
                            >
                              <StarRating rating={review.rating} />{" "}
                            </div>
                            <div
                              className="col-sm-9 my-2"
                              style={{ marginLeft: "10px" }}
                            >
                              <h6 className="mt-1">{review.userName}</h6>
                              <p>{review.comment}</p>
                              <p style={{ fontSize: "12px", color: "gray" }}>
                                ({" "}
                                {new Date(review.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}{" "}
                                ){" "}
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    color: "#33d933",
                                  }}
                                />
                                verified{" "}
                                {review?.userName?.startsWith("admin")
                                  ? "supplier"
                                  : "customer"}
                              </p>
                              {isLoggedIn && cart.role === "admin" && (
                                <div
                                  className=""
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    onClick={() => setShowWarning(true)}
                                    style={{
                                      marginRight: "5px",
                                      color: "#ff3333",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {showWarning && (
                          <ConfirmPopup
                            msg={`Are you sure you want to delete this review?`}
                            onConfirm={() => handleSubmit(review._id)}
                            onCancel={() => setShowWarning(false)}
                          />
                        )}
                      </div>
                    ))}
                    <Pagination />
                  </div>
                </div>
              )}
            </div>
          </Tab>
          <Tab eventKey="Seller" title="Seller">
            {/* <div className="box mb-1 border rounded p-4">
              <span className="">
                <strong>What to Expect In Box</strong>
              </span>
              <p dangerouslySetInnerHTML={{ __html: data.box }}></p>
            </div> */}
            <div className="seller-info border rounded p-4">
              <h4 className="mb-4">Seller Information</h4>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <p className="mb-1 border rounded p-4 text-center">
                      <span className="icon-border-rounded">
                        <BsPerson />
                      </span>
                      <p>Ninety One Stores</p>
                    </p>
                    <p className="mb-1 border rounded p-4 text-center">
                      <span className="icon-border-rounded">
                        <BsEnvelope />
                      </span>
                      <p>Email:samuel@gmail.com</p>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <p className="mb-1 border rounded p-4 text-center">
                      <span className="icon-border-rounded">
                        <BsTelephone />
                      </span>
                      <p>Phone: 0712012113</p>
                    </p>
                    <p className="mb-1 border rounded p-4 text-center">
                      <span className="icon-border-rounded">
                        <BsGeoAlt />
                      </span>
                      <p>
                        Kimbo Ruiru, <br />
                        Kimbo Matangi Road <br /> Matangi Town
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default FullDescription;
