const quotes = [
  "Success is not final, failure is not fatal: It is the courage to continue that counts.",
  "Believe you can and you're halfway there.",
  "The only way to do great work is to love what you do.",
  "Don't watch the clock; do what it does. Keep going.",
  "The future belongs to those who believe in the beauty of their dreams.",
  "The only limit to our realization of tomorrow will be our doubts of today.",
  "The road to success and the road to failure are almost exactly the same.",
  "Your limitation—it's only your imagination.",
  "Push yourself, because no one else is going to do it for you.",
  "Great things never come from comfort zones.",
  "Dream it. Wish it. Do it.",
  "Success doesn’t just find you. You have to go out and get it.",
  "The harder you work for something, the greater you’ll feel when you achieve it.",
  "Dream bigger. Do bigger.",
  "Don’t stop when you’re tired. Stop when you’re done.",
  "Wake up with determination. Go to bed with satisfaction.",
  "Do something today that your future self will thank you for.",
  "Little things make big days.",
  "It’s going to be hard, but hard does not mean impossible.",
  "Don’t wait for opportunity. Create it.",
  "Sometimes we’re tested not to show our weaknesses, but to discover our strengths.",
  "The key to success is to focus on goals, not obstacles.",
  "Dream it. Believe it. Build it.",
  "Do it with passion or not at all.",
  "Do something today that your future self will thank you for.",
  "Little things make big days.",
  "It’s going to be hard, but hard does not mean impossible.",
  "Don’t wait for opportunity. Create it.",
  "Sometimes we’re tested not to show our weaknesses, but to discover our strengths.",
  "The key to success is to focus on goals, not obstacles.",
  "Dream it. Believe it. Build it.",
  "Do it with passion or not at all.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Someday is not a day of the week.",
  "It’s not whether you get knocked down, it’s whether you get up.",
  "The only way to do great work is to love what you do.",
  "If you believe in yourself, anything is possible.",
  "The secret of getting ahead is getting started.",
  "Don’t count the days, make the days count.",
  "If you want to achieve greatness stop asking for permission.",
  "Keep your face always toward the sunshine—and shadows will fall behind you.",
  "Success is not how high you have climbed, but how you make a positive difference to the world.",
  "Your time is limited, don’t waste it living someone else’s life.",
  "The only way to do great work is to love what you do.",
  "Believe you can and you’re halfway there.",
  "You are never too old to set another goal or to dream a new dream.",
  "The future belongs to those who believe in the beauty of their dreams.",
  "The journey of a thousand miles begins with one step.",
  "The best time to plant a tree was 20 years ago. The second best time is now.",
  "It’s not what you look at that matters, it’s what you see.",
  "The only person you should try to be better than is the person you were yesterday.",
  "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
  "Don’t watch the clock; do what it does. Keep going.",
  "You have to be at your strongest when you’re feeling at your weakest.",
  "The only way to achieve the impossible is to believe it is possible.",
  "Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "You are never too old to set another goal or to dream a new dream.",
  "The future belongs to those who believe in the beauty of their dreams.",
  "The journey of a thousand miles begins with one step.",
];
export default quotes;
