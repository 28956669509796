import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// components
import { showLoginModal } from "../../store/slices/loginModalSlice";
import { showCartModal } from "../../store/slices/cartModalSlice";
import {
  faUnlock,
  faCartShopping,
  faUser,
  faUserGear,
  faSignInAlt,
  faRightToBracket,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import "./header.css";
import axiosInstance from "../../apis/config";
import PopoverContent from "./popover";
import { showToast } from "../../store/slices/toastSlice";
import { TypeAnimation } from "react-type-animation";
import { FaXTwitter } from "react-icons/fa6";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaHeadphonesAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const Header2 = ({ isMediumScreen, cart }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(false);

  const cartOpen = useSelector((state) => state.cartModal.show);
  console.log("cart", cartOpen);

  const openSearchModal = (e) => {
    e.stopPropagation();
    setSearchOpen(true);
  };
  const closeSearchModal = (e) => {
    e.stopPropagation();
    setSearchOpen(false);
  };

  const goToSomewhere = (e, link) => {
    e.stopPropagation();
    navigate(link);
    setActive("");
    if (location.pathname.startsWith("/shop")) {
      window.location.reload();
    }
  };
  const openMenu = (e) => {
    e.stopPropagation();

    setActive("active");
  };
  const closeMenu = (e) => {
    e.stopPropagation();

    setActive("");
  };
  const logOut = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      localStorage.removeItem("userToken");
      navigate("/");
      window.location.reload();
      dispatch(showToast("See you when We see you!"));
    } else {
      dispatch(showLoginModal(true));
    }
    setActive("");
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setIsLoggedIn(true);
        setDecodedToken(decoded);
      }
    } else {
      setIsLoggedIn(false);
      setDecodedToken(null);
    }
  }, [cart]);

  //head functionality
  useEffect(() => {
    // Function to handle submenu toggle
    const handleSubMenuToggle = (event) => {
      const parentMenuCategory = event.target.closest(".menu-category");
      const submenu = parentMenuCategory.querySelector(
        ".submenu-category-list"
      );
      submenu.classList.toggle("active");

      // Toggle the icon
      const icon = event.target;
      if (submenu.classList.contains("active")) {
        icon.setAttribute("name", "remove-outline");
      } else {
        icon.setAttribute("name", "add-outline");
      }
    };

    // Add event listener to all add-icons
    const addIcons = document.querySelectorAll(".add-icon");
    addIcons.forEach((icon) => {
      icon.addEventListener("click", handleSubMenuToggle);
    });

    // Cleanup: Remove event listeners
    return () => {
      addIcons.forEach((icon) => {
        icon.removeEventListener("click", handleSubMenuToggle);
      });
    };
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        event.target.closest("[data-mobile-menu]") === null &&
        active !== ""
      ) {
        setActive("");
      }
    };
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [active]);

  const [query, setQuery] = useState("");

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      search();
      setSearchOpen(false);
    }
  };

  const search = (e) => {
    if (query.trim()) {
      const url = `/search?page=1&query=${encodeURIComponent(query.trim())}`;
      navigate(url);
      setSearchOpen(false);
    }
  };
  function handelLogout() {
    if (isLoggedIn) {
      localStorage.removeItem("userToken");
      navigate("/");
      window.location.reload();
      dispatch(showToast("See you when We see you!"));
    } else {
      dispatch(showLoginModal(true));
    }
  }

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/categories`, {})
      .then((res) => {
        axiosInstance
          .get(`/brands`, {})
          .then((res) => {
            setBrands(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
        const shuffledCategories = res.data.data.sort(
          () => Math.random() - 0.5
        );
        const randomFourCategories = shuffledCategories.slice(0, 4);
        setCategories(randomFourCategories);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  //first cat
  const category = categories[0];
  const filteredBrands = category
    ? brands.filter((brand) => brand.category === category.name)
    : [];
  const shuffledFilteredBrands = filteredBrands.sort(() => Math.random() - 0.5);
  const randomFiveBrands = shuffledFilteredBrands.slice(0, 5);

  console.log("random brand", randomFiveBrands);
  // second cat
  const category2 = categories[1];
  const filteredBrands2 = category2
    ? brands.filter((brand) => brand.category === category2.name)
    : [];
  const shuffledFilteredBrands2 = filteredBrands2.sort(
    () => Math.random() - 0.5
  );
  const randomFiveBrands2 = shuffledFilteredBrands2.slice(0, 5);

  //third cat
  const category3 = categories[2];
  const filteredBrands3 = category3
    ? brands.filter((brand) => brand.category === category3.name)
    : [];
  const shuffledFilteredBrands3 = filteredBrands3.sort(
    () => Math.random() - 0.5
  );
  const randomFiveBrands3 = shuffledFilteredBrands3.slice(0, 5);

  //fourth cat
  const category4 = categories[3];
  const filteredBrands4 = category4
    ? brands.filter((brand) => brand.category === category4.name)
    : [];
  const shuffledFilteredBrands4 = filteredBrands4.sort(
    () => Math.random() - 0.5
  );
  const randomFiveBrands4 = shuffledFilteredBrands4.slice(0, 5);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  const targetRef = useRef(null);
  const targetRef2 = useRef(null);

  useEffect(() => {
    if (cart.items?.length === 1 && !cartOpen) {
      setShowPopover2(true);
      setShowPopover(true);
      if (isMediumScreen === false) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [cart, isMediumScreen, cartOpen]);
  useEffect(() => {
    let timer;
    if (showPopover) {
      timer = setTimeout(() => {
        setShowPopover(false);
      }, 5000);
    }
    if (showPopover2) {
      timer = setTimeout(() => {
        setShowPopover2(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showPopover, showPopover2]);

  return (
    <header>
      <div class="header-top">
        <div class="container">
          <ul className="header-social-container">
            <li>
              <a
                href="https://www.facebook.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiFacebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.twitter.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter style={{ fontSize: "15px" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+254712012113"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsWhatsapp />
              </a>
            </li>
          </ul>
          {/* desktop */}
          <div>
            <div className="header-alert-news">
              <b>
                <TypeAnimation
                  sequence={[
                    "Welcome to Ninety One Shop",
                    2000,
                    "Use Coupon for purchases",
                    2000,
                    "Call/WhatsApp 0712012113 to order",
                    2000,
                    "Cash On Delivery order above Ksh. 5000",
                    2000,
                  ]}
                  wrapper="span"
                  speed={50}
                  style={{ fontSize: "0.75rem", display: "inline-block" }}
                  repeat={Infinity}
                />
              </b>
            </div>
          </div>

          <div class="header-top-actions">
            {/* <select name="currency">
              <option value="kes">KES </option>
              <option value="usd">USD</option>
            </select>

            <select name="language">
              <option value="en-US">English</option>
              <option value="es-ES">Swahili</option>
            </select> */}
            <div className="" style={{ display: "flex", color: "#514c4c" }}>
              <div style={{ marginRight: "6px" }}>
                <FaHeadphonesAlt />
              </div>
              <div style={{ marginTop: "2px" }}> 0712 012 113</div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div class="header-main">
        <div class="header-alert-news-two">
          <p>
            <b>
              <TypeAnimation
                sequence={[
                  "Welcome to Ninety One Shop",
                  2000,
                  "Use Coupon for purchases",
                  2000,
                  "Call/WhatsApp 0712012113 to order",
                  2000,

                  "Cash On Delivery order above Ksh. 5000",
                  2000,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: "0.75rem", display: "inline-block" }}
                repeat={Infinity}
              />
            </b>
          </p>
        </div>
        <div class="container">
          <Link to="/" class="header-logo logo-filter">
            <img
              src="https://res.cloudinary.com/bramuels/image/upload/v1695878268/logo/LOGO-01_moo9oc.png"
              alt="91's logo"
              width="110"
              height="70"
            />
          </Link>

          <div class="header-search-container">
            <input
              type="search"
              name="search"
              class="search-field"
              placeholder="Enter your product name..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleSearch}
            />

            <button class="search-btn" onClick={(e) => search(e)}>
              <FaSearch color="#838080" />{" "}
            </button>
          </div>

          <div class="header-user-actions">
            {/* </Link> */}
            <button class="action-btn">
              {isLoggedIn ? (
                <Link
                  className="btn p-0 color-main-gray fs-5 hover-color-yellow"
                  to={
                    cart?.role === "user"
                      ? `/account/${decodedToken.id}`
                      : "/dashboard"
                  }
                >
                  <FontAwesomeIcon
                    icon={cart?.role === "user" ? faUser : faUserGear}
                    style={{ fontSize: "25px" }}
                  />
                  <span className="visually-hidden">
                    {cart?.role === "user" ? "account" : "dashboard"}
                  </span>
                </Link>
              ) : (
                <button
                  className="btn p-0 color-main-gray hover-color-yellow"
                  onClick={() => dispatch(showLoginModal(true))}
                >
                  <FontAwesomeIcon
                    icon={faUnlock}
                    style={{ fontSize: "25px" }}
                  />{" "}
                </button>
              )}
            </button>

            <button class="action-btn" onClick={handelLogout}>
              <FontAwesomeIcon
                icon={faSignInAlt}
                style={{ fontSize: "25px" }}
              />
            </button>
            {/* cart  */}
            {!(cart.role && cart.role === "admin") && (
              <button
                type="button"
                className="cart-btn btn p-0 fs-5 hover-color-yellow position-relative"
                ref={targetRef}
                onClick={() =>
                  decodedToken
                    ? dispatch(showCartModal(true))
                    : dispatch(showLoginModal(true))
                }
              >
                <FontAwesomeIcon
                  icon={faCartShopping}
                  style={{ fontSize: "25px" }}
                />
                <span className="visually-hidden">cart</span>
                <span className="position-absolute bg-yellow top-0 start-0 translate-middle badge rounded-pill">
                  {cart.items
                    ? cart.items?.length > 99
                      ? "99+"
                      : cart.items?.length
                    : 0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </button>
            )}
          </div>
          <PopoverContent
            show={showPopover}
            targetRef={targetRef}
            message={"Your Cart is here 🥰"}
            place={"left"}
            onClose={() => setShowPopover(false)}
          />
          <PopoverContent
            show={showPopover2}
            targetRef={targetRef2}
            message={"Your Cart is here 🥰"}
            place={"top"}
            onClose={() => setShowPopover2(false)}
          />
        </div>
      </div>
      <nav class="desktop-navigation-menu">
        <div class="container">
          <ul class="desktop-menu-category-list">
            <li class="menu-category">
              <Link to="/" class="menu-title">
                Home
              </Link>
            </li>

            <li
              class="menu-category"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link to="/shop" class="menu-title">
                Categories
              </Link>

              <div class="dropdown-panel">
                <ul class="dropdown-panel-list">
                  <li class="menu-title text-capitalize">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[0]?._id}&sort=0&price=0`}
                    >
                      {categories[0]?.name}
                    </Link>
                  </li>
                  {randomFiveBrands.length === 1 ? (
                    <>
                      {randomFiveBrands.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands.length === 2 ? (
                    <>
                      {randomFiveBrands.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands.length === 3 ? (
                    <>
                      {randomFiveBrands.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands.length === 4 ? (
                    <>
                      {randomFiveBrands.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : (
                    randomFiveBrands.map((brand) => (
                      <li key={brand._id} className="panel-list-item">
                        <Link
                          to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                        >
                          {brand.name}
                        </Link>
                      </li>
                    ))
                  )}
                  <li class="panel-list-item">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[0]?._id}&sort=0&price=0`}
                    >
                      <img
                        src={`${categories[0]?.image[0]?.url}`}
                        alt="headphone collection"
                        style={{
                          maxHeight: "150px",
                          maxWidth: "150px",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      />
                    </Link>
                  </li>
                </ul>
                <ul class="dropdown-panel-list">
                  <li class="menu-title text-capitalize">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[1]?._id}&sort=0&price=0`}
                    >
                      {categories[1]?.name}
                    </Link>
                  </li>

                  {randomFiveBrands2.length === 1 ? (
                    <>
                      {randomFiveBrands2.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands2.length === 2 ? (
                    <>
                      {randomFiveBrands2.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands2.length === 3 ? (
                    <>
                      {randomFiveBrands2.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands2.length === 4 ? (
                    <>
                      {randomFiveBrands2.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : (
                    randomFiveBrands2.map((brand) => (
                      <li key={brand._id} className="panel-list-item">
                        <Link
                          to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                        >
                          {brand.name}
                        </Link>
                      </li>
                    ))
                  )}
                  <li class="panel-list-item">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[1]?._id}&sort=0&price=0`}
                    >
                      <img
                        src={`${categories[1]?.image[0]?.url}`}
                        alt="men's fashion"
                        style={{
                          maxHeight: "150px",
                          maxWidth: "150px",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      />
                    </Link>
                  </li>
                </ul>
                <ul class="dropdown-panel-list">
                  <li class="menu-title text-capitalize">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[2]?._id}&sort=0&price=0`}
                    >
                      {categories[2]?.name}
                    </Link>
                  </li>

                  {randomFiveBrands3.length === 1 ? (
                    <>
                      {randomFiveBrands3.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands3.length === 2 ? (
                    <>
                      {randomFiveBrands3.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands3.length === 3 ? (
                    <>
                      {randomFiveBrands3.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands3.length === 4 ? (
                    <>
                      {randomFiveBrands3.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : (
                    randomFiveBrands3.map((brand) => (
                      <li key={brand._id} className="panel-list-item">
                        <Link
                          to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                        >
                          {brand.name}
                        </Link>
                      </li>
                    ))
                  )}

                  <li class="panel-list-item">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[2]?._id}&sort=0&price=0`}
                    >
                      <img
                        src={`${categories[2]?.image[0]?.url}`}
                        alt="women's fashion"
                        style={{
                          maxHeight: "150px",
                          maxWidth: "150px",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      />
                    </Link>
                  </li>
                </ul>
                <ul class="dropdown-panel-list">
                  <li class="menu-title text-capitalize">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[3]?._id}&sort=0&price=0`}
                    >
                      {categories[3]?.name}
                    </Link>
                  </li>

                  {randomFiveBrands4.length === 1 ? (
                    <>
                      {randomFiveBrands4.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands4.length === 2 ? (
                    <>
                      {randomFiveBrands4.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands4.length === 3 ? (
                    <>
                      {randomFiveBrands4.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : randomFiveBrands4.length === 4 ? (
                    <>
                      {randomFiveBrands4.map((brand) => (
                        <li key={brand._id} className="panel-list-item">
                          <Link
                            to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                          >
                            {brand.name}
                          </Link>
                        </li>
                      ))}
                      <li className="panel-list-item">
                        <Link to={`/shop`}>
                          {" "}
                          <span class="placeholder col-6 rounded"></span>{" "}
                        </Link>
                      </li>
                    </>
                  ) : (
                    randomFiveBrands4.map((brand) => (
                      <li key={brand._id} className="panel-list-item">
                        <Link
                          to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                        >
                          {brand.name}
                        </Link>
                      </li>
                    ))
                  )}
                  <li class="panel-list-item">
                    <Link
                      to={`/shop?page=1&brand=all&category=${categories[3]?._id}&sort=0&price=0`}
                    >
                      <img
                        src={`${categories[3]?.image[0]?.url}`}
                        alt="mouse collection"
                        style={{
                          maxHeight: "150px",
                          maxWidth: "150px",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                        // width="10"
                        // height="10"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li
              class="menu-category"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link
                to={`/shop?page=1&brand=all&category=${categories[0]?._id}&sort=0&price=0`}
                class="menu-title"
              >
                {loading ? <p>coming</p> : categories[0]?.name}
              </Link>

              <ul class="dropdown-list">
                {randomFiveBrands.map((brand) => (
                  <li key={brand._id} class="dropdown-item">
                    <Link
                      to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                    >
                      {brand.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              class="menu-category"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link
                to={`/shop?page=1&brand=all&category=${categories[1]?._id}&sort=0&price=0`}
                class="menu-title"
              >
                {loading ? <p>coming</p> : categories[1]?.name}
              </Link>

              <ul class="dropdown-list">
                {randomFiveBrands2.map((brand) => (
                  <li key={brand._id} class="dropdown-item">
                    <Link
                      to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                    >
                      {brand.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              class="menu-category"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link
                to={`/shop?page=1&brand=all&category=${categories[2]?._id}&sort=0&price=0`}
                class="menu-title"
              >
                {loading ? <p>Appearing</p> : categories[2]?.name}
              </Link>

              <ul class="dropdown-list">
                {randomFiveBrands3.map((brand) => (
                  <li key={brand._id} class="dropdown-item">
                    <Link
                      to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                    >
                      {brand.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              class="menu-category"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link
                to={`/shop?page=1&brand=all&category=${categories[3]?._id}&sort=0&price=0`}
                class="menu-title"
              >
                {loading ? <p>showing</p> : categories[3]?.name}
              </Link>

              <ul class="dropdown-list">
                {randomFiveBrands4.map((brand) => (
                  <li key={brand._id} class="dropdown-item">
                    <Link
                      to={`/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`}
                    >
                      {brand.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li class="menu-category">
              <Link to="/shop" class="menu-title">
                Shop
              </Link>
            </li>
            <li class="menu-category">
              <Link to="/" class="menu-title">
                Others
              </Link>

              <ul class="dropdown-list">
                <li class="dropdown-item">
                  <Link to="/about">About</Link>
                </li>

                <li class="dropdown-item">
                  <Link to="/contact">Contact</Link>
                </li>

                <li class="dropdown-item">
                  <Link to="/faq">FAQs</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div class="mobile-bottom-navigation">
        <button
          onClick={(e) => openMenu(e)}
          class="action-btn"
          data-mobile-menu-open-btn
        >
          <IoMdMenu />{" "}
        </button>
        {!(cart.role && cart.role === "admin") && (
          <div className=" text-center">
            <div>
              <button
                type="button"
                className="cart-btn btn lh-1 p-0 fs-5 hover-color-yellow position-relative"
                ref={targetRef2}
                onClick={() =>
                  cart.user_id
                    ? dispatch(showCartModal(true))
                    : dispatch(showLoginModal(true))
                }
              >
                <FontAwesomeIcon icon={faCartShopping} />
                <span className="visually-hidden">cart</span>
                <span className="position-absolute bg-yellow top-0 start-0 translate-middle badge rounded-pill">
                  {cart.items
                    ? cart.items?.length > 99
                      ? "99+"
                      : cart.items?.length
                    : 0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </button>
            </div>
          </div>
        )}
        <Link to="/">
          <button class="action-btn">
            <IoHome />{" "}
          </button>
        </Link>
        <div className={`${cart.role === "admin" ? "col-3" : ""} text-center`}>
          <div>
            <button
              onClick={(e) => openSearchModal(e)}
              className="btn lh-1 p-0 fs-5 color-main-gray hover-color-yellow"
            >
              <FaSearch />
              <span className="visually-hidden">search</span>
            </button>
          </div>
        </div>
        <div>
          {cart.user_id ? (
            <Link
              className="btn p-0 lh-1 color-main-gray fs-5 hover-color-yellow"
              to={`/account/${cart.user_id}`}
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="visually-hidden">account</span>
            </Link>
          ) : cart.role === "admin" ? (
            <Link
              className="btn p-0 lh-1 color-main-gray fs-5 hover-color-yellow"
              to={`/dashboard`}
            >
              <FontAwesomeIcon icon={faUserGear} />
              <span className="visually-hidden">dashboard</span>
            </Link>
          ) : (
            <button
              className="btn p-0 lh-1 fs-5 color-main-gray hover-color-yellow"
              onClick={() => dispatch(showLoginModal(true))}
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="visually-hidden">login / register</span>
            </button>
          )}
        </div>
      </div>
      <nav
        className={`mobile-navigation-menu has-scrollbar ${active}`}
        data-mobile-menu
      >
        <div class="menu-top">
          <h2 class="menu-title">Menu</h2>

          <button
            onClick={(e) => closeMenu(e)}
            class="menu-close-btn"
            data-mobile-menu-close-btn
          >
            <IoClose />{" "}
          </button>
        </div>

        <ul class="mobile-menu-category-list" style={{ marginBottom: 0 }}>
          <li class="menu-category">
            <button onClick={(e) => goToSomewhere(e, "/")} class="menu-title">
              Home
            </button>
          </li>
          <li class="menu-category">
            <button
              onClick={(e) => goToSomewhere(e, "/shop")}
              class="menu-title"
            >
              Shop
            </button>
          </li>
          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title">Categories</p>
              <div>
                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                <ion-icon name="remove-outline" class="remove-icon"></ion-icon>
              </div>
            </button>

            <ul class="submenu-category-list" data-accordion>
              <li class="submenu-category">
                <button
                  onClick={(e) =>
                    goToSomewhere(
                      e,
                      `/shop?page=1&brand=all&category=${categories[0]?._id}&sort=0&price=0`
                    )
                  }
                  class="submenu-title text-capitalize"
                >
                  {categories[0]?.name}
                </button>
              </li>
              <li class="submenu-category">
                <button
                  onClick={(e) =>
                    goToSomewhere(
                      e,
                      `/shop?page=1&brand=all&category=${categories[1]?._id}&sort=0&price=0`
                    )
                  }
                  class="submenu-title text-capitalize"
                >
                  {categories[1]?.name}
                </button>
              </li>{" "}
              <li class="submenu-category">
                <button
                  onClick={(e) =>
                    goToSomewhere(
                      e,
                      `/shop?page=1&brand=all&category=${categories[2]?._id}&sort=0&price=0`
                    )
                  }
                  class="submenu-title text-capitalize"
                >
                  {categories[2]?.name}
                </button>
              </li>{" "}
              <li class="submenu-category">
                <button
                  onClick={(e) =>
                    goToSomewhere(
                      e,
                      `/shop?page=1&brand=all&category=${categories[3]?._id}&sort=0&price=0`
                    )
                  }
                  class="submenu-title text-capitalize"
                >
                  {categories[3]?.name}
                </button>
              </li>
            </ul>
          </li>
          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title text-capitalize">{categories[0]?.name}</p>

              <div>
                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                <ion-icon name="remove-outline" class="remove-icon"></ion-icon>
              </div>
            </button>

            <ul class="submenu-category-list" data-accordion>
              {randomFiveBrands.map((brand) => (
                <li class="submenu-category">
                  <button
                    onClick={(e) =>
                      goToSomewhere(
                        e,
                        `/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`
                      )
                    }
                    class="submenu-title text-capitalize"
                  >
                    {brand.name}
                  </button>
                </li>
              ))}
            </ul>
          </li>

          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title text-capitalize">{categories[1]?.name}</p>

              <div>
                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                <ion-icon name="remove-outline" class="remove-icon"></ion-icon>
              </div>
            </button>

            <ul class="submenu-category-list" data-accordion>
              {randomFiveBrands2.map((brand) => (
                <li class="submenu-category">
                  <button
                    onClick={(e) =>
                      goToSomewhere(
                        e,
                        `/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`
                      )
                    }
                    class="submenu-title text-capitalize"
                  >
                    {brand.name}
                  </button>
                </li>
              ))}
            </ul>
          </li>

          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title text-capitalize">{categories[2]?.name}</p>

              <div>
                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                <ion-icon name="remove-outline" class="remove-icon"></ion-icon>
              </div>
            </button>

            <ul class="submenu-category-list" data-accordion>
              {randomFiveBrands3.map((brand) => (
                <li class="submenu-category">
                  <button
                    onClick={(e) =>
                      goToSomewhere(
                        e,
                        `/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`
                      )
                    }
                    class="submenu-title text-capitalize"
                  >
                    {brand.name}
                  </button>
                </li>
              ))}
            </ul>
          </li>

          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title text-capitalize">{categories[3]?.name}</p>

              <div>
                <ion-icon name="add-outline" class="add-icon"></ion-icon>
                <ion-icon name="remove-outline" class="remove-icon"></ion-icon>
              </div>
            </button>

            <ul class="submenu-category-list" data-accordion>
              {randomFiveBrands4.map((brand) => (
                <li class="submenu-category">
                  <button
                    onClick={(e) =>
                      goToSomewhere(
                        e,
                        `/shop?page=1&brand=${brand?._id}&category=all&sort=0&price=0`
                      )
                    }
                    class="submenu-title text-capitalize"
                  >
                    {brand.name}
                  </button>
                </li>
              ))}
            </ul>
          </li>
        </ul>

        <div class="menu-bottom" style={{ marginBottom: "15px" }}>
          <ul class="menu-category-list">
            <li class="menu-category">
              <button class="accordion-menu" data-accordion-btn>
                <p class="menu-title">Others</p>

                <div>
                  <ion-icon name="add-outline" class="add-icon"></ion-icon>
                  <ion-icon
                    name="remove-outline"
                    class="remove-icon"
                  ></ion-icon>
                </div>
              </button>

              <ul class="submenu-category-list" data-accordion>
                <li class="submenu-category">
                  <button
                    onClick={(e) => goToSomewhere(e, "/about")}
                    class="submenu-title"
                  >
                    About
                  </button>
                </li>

                <li class="submenu-category">
                  <button
                    onClick={(e) => goToSomewhere(e, "/contact")}
                    class="submenu-title"
                  >
                    Contacts
                  </button>
                </li>
                <li class="submenu-category">
                  <button
                    onClick={(e) => goToSomewhere(e, "/faq")}
                    class="submenu-title"
                  >
                    FAQs
                  </button>
                </li>
              </ul>
            </li>
            <li class="menu-category">
              <div className="" style={{ display: "flex", color: "#514c4c" }}>
                <div style={{ marginRight: "6px" }}>
                  <FaHeadphonesAlt />
                </div>
                <div style={{ marginTop: "2px" }}> 0712 012 113</div>
              </div>
            </li>
          </ul>
          <ul class="mobile-menu-category-list">
            <li class="menu-category">
              <button
                onClick={(e) => logOut(e)}
                class="menu-title align-self-start lh-1 btn px-2 py-2 d-flex align-items-center gap-2"
              >
                {isLoggedIn ? (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    Log Out
                  </>
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faRightToBracket} />
                    Log In or Register
                  </>
                )}
              </button>
            </li>
          </ul>

          <ul class="menu-social-container">
            <li>
              <li>
                <a
                  href="https://www.facebook.com"
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFacebook />
                </a>
              </li>
            </li>

            <li>
              <a
                href="https://www.twitter.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter style={{ fontSize: "15px" }} />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>

            <li>
              <a
                href="https://wa.me/+254712012113"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsWhatsapp />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* mobile search bar */}
      <div className="mobile-search">
        <div
          className={`search-container ${searchOpen ? "active" : ""}`}
          data-search-box
          style={{ backdropFilter: "blur(5px)", zIndex: "9999" }}
          onClick={(e) => closeSearchModal(e)}
        >
          <div className="container">
            <button
              className="search-close-btn"
              aria-label="Close search"
              onClick={(e) => closeSearchModal(e)}
            >
              <IoClose className="icon" style={{ marginLeft: 0 }} />
            </button>

            <div className="search-wrapper" onClick={(e) => openSearchModal(e)}>
              <input
                type="search"
                name="search"
                placeholder="What are you looking for?"
                aria-label="Search"
                className="search-field"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleSearch}
              />

              <button
                className="search-submit"
                aria-label="Submit"
                onClick={(e) => {
                  search(e);
                  closeSearchModal(e);
                }}
              >
                <FiSearch className="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
