import styles from "../../pages/about/about.module.css";

export default function WhatTell() {
  return (
    <div className={`${styles.thecontent} container`}>
      <h1 className={`pt-5`}> what they're saying</h1>
      <p className={`pb-3 pt-5`}>
        "Shopping at this online shop was a delightful experience from start to
        finish. The selection of furniture is diverse and stylish, catering to
        various tastes and preferences. I was pleasantly surprised by the
        affordability of the pieces, considering their high-quality
        craftsmanship. The customer service was exceptional, with prompt
        responses to my inquiries and helpful guidance throughout the purchasing
        process. I appreciated how the company prioritizes sustainability and
        minimizes its environmental impact, aligning with my values. Overall, I
        couldn't be happier with my purchase, and I will definitely be returning
        for future furniture needs!"
      </p>
      <h5>Anthony Ndung'u</h5>
      <div>
        <h6 className="mb-4">Analyst</h6>
      </div>
    </div>
  );
}
