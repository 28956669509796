// style
import { useRef, useState } from "react";
import style from "../../pages/productDetails/productDetails.module.css";
import PopoverContent from "../common/popover";
import { useEffect } from "react";

const AdditionalInfo = ({ product }) => {
  console.log("product", product);
  const [showPopover, setShowPopover] = useState(true);
  const targetRef = useRef(null);
  useEffect(() => {
    setShowPopover(true);
  }, []);

  useEffect(() => {
    let timer;
    if (showPopover) {
      timer = setTimeout(() => {
        setShowPopover(false);
      }, 60000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showPopover]);
  return (
    <ul className={`${style["additional-info-list"]} list-unstyled`}>
      <li className="mb-2">
        <span className={`${style.key} d-inline-block color-main-gray`}>
          Category:
        </span>
        <span className="color-secondary-gray"> {product.category.name}</span>
      </li>
      <li>
        <span className={`${style.key} d-inline-block color-main-gray`}>
          Brand:
        </span>
        <span className="color-secondary-gray text-capitalize">
          {product.brand.name}
        </span>
        <li className="mb-2">
          <span className={`${style.key} d-inline-block color-main-gray`}>
            What's In Box:
          </span>
          <span className="color-secondary-gray" ref={targetRef}>
            {" "}
            <p dangerouslySetInnerHTML={{ __html: product?.box }}></p>
          </span>
        </li>
      </li>
      <PopoverContent
        show={showPopover}
        targetRef={targetRef}
        message={"What will be in the Box 🥰"}
        place={"bottom"}
        onClose={() => setShowPopover(false)}
      />
    </ul>
  );
};

export default AdditionalInfo;
