import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbcomponant() {
  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="">
              <Link to="/" className={`text-decoration-none `}>
                Home <span className="text-secondary">{">>"}</span>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to="/cart"
                className={`text-decoration-none `}
                style={{ margin: "0 6px" }}
              >
                Cart
              </Link>
            </li>
            <li className="">
              <Link
                to="/checkout"
                className={`text-decoration-none  `}
                style={{ margin: "0 6px" }}
              >
                <span className="text-secondary">{">>"}</span> information{" "}
                <span className="text-secondary">{">>"}</span>
              </Link>
            </li>
            <li className={`breadcrumb-item `} aria-current="page">
              shipping
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
}
