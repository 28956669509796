import React, { useState, useEffect } from "react";
import welcomeImg from "../../../assets/dashboard/man-with-laptop-light.png";
import style from "../../../pages/dashboard/dashboard.module.css";
import jwtDecode from "jwt-decode";
import quotes from "../../../quotes";

const WelcomeCard = () => {
  const token = localStorage.getItem("userToken");
  const admin = jwtDecode(token);

  const [quote, setQuote] = useState("");

  const getGreeting = () => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      return "Good morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      return "Good afternoon";
    } else if (currentTime >= 18 && currentTime < 22) {
      return "Good evening";
    } else {
      return "Good night";
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);

  return (
    <div className="bg-white rounded-3 pt-3 px-3 row m-0 mb-3">
      <div className="col-md-8 px-0 pe-md-3 pb-3 text-center text-md-start mb-md-0 mb-4">
        <h2 className={`h5 ${style["dash-purple"]} mb-3`}>
          {getGreeting()}, {admin?.role} {admin?.name}!&#127881;
        </h2>
        <span className="fw-semibold">
          Special Quote of the Day Just for You!
        </span>
        <p className="mb-0">{quote}</p>
      </div>
      <div className="col-md-4 px-0 ps-md-1 align-self-md-end">
        <img
          src={welcomeImg}
          alt=""
          className={`img-fluid d-block mx-md-0 mx-auto ${style["dash-welcome-img"]}`}
        />
      </div>
    </div>
  );
};

export default WelcomeCard;
