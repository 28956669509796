import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//components
import Breadcrumbcomponant from "../../components/checkout/Breadcrumbcomponant";
//style
import style from "./checkout.module.css";

const Checkout = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");
  const cart = useSelector((state) => state.cart.cart);
  useEffect(() => {
    if (!token) {
      navigate("/404", { replace: true });
      return;
    }
  });
  useEffect(() => {
    if (cart?.totalPrice === 0 || cart?.role === "admin") {
      navigate("/home", { replace: true });
      return;
    }
  });

  return (
    <div className={`${style.checkout}  `}>
      <div className={`${style.checkoutContainer}  mt-2  ms-0   `}>
        <Link to="/home" className="logo d-flex justify-content-center">
          <img
            src="https://res.cloudinary.com/bramuels/image/upload/v1695878268/logo/LOGO-01_moo9oc.png"
            alt="91's logo"
            width="110"
            height="70"
            className={`${style.logoCheckout}`}
          />
        </Link>
        <div className="mb-4 ms-4">
          <Breadcrumbcomponant />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Checkout;
