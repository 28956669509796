import React from "react";
import HomeProductsCard from "./homeProductsCard";

const RecentlyViewedProducts = () => {
  const recentlyViewed = JSON.parse(
    localStorage.getItem("recentlyViewedProducts")
  );

  return (
    <div class="product-container">
      <div class="container">
        <div class="product-main">
          {recentlyViewed && (
            <h2 class="title m-2 he-headers">
              <span>Recently</span> Viewed
            </h2>
          )}
          <div class="product-grid">
            {recentlyViewed?.map((product) => (
              <HomeProductsCard key={product._id} product={product} show />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentlyViewedProducts;
