import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../store/slices/toastSlice";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../apis/config";

const Footer = () => {
  const [showFollowUs, setShowFollowUs] = useState(false);
  const [showHereToHelp, setShowHereToHelp] = useState(false);
  const [showCustomerService, setShowCustomerService] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    updateStates();
    window.addEventListener("resize", updateStates);
    return () => {
      window.removeEventListener("resize", updateStates);
    };
  }, []);

  const updateStates = () => {
    if (window.innerWidth <= 576) {
      setShowFollowUs(false);
      setShowHereToHelp(false);
      setShowCustomerService(false);
    } else {
      setShowFollowUs(true);
      setShowHereToHelp(true);
      setShowCustomerService(true);
    }
  };

  const toggleFollowUs = () => {
    if (window.innerWidth <= 576) {
      setShowFollowUs((prevState) => !prevState);
    }
  };

  const toggleHereToHelp = () => {
    if (window.innerWidth <= 576) {
      setShowHereToHelp((prevState) => !prevState);
    }
  };

  const toggleCustomerService = () => {
    if (window.innerWidth <= 576) {
      setShowCustomerService((prevState) => !prevState);
    }
  };

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(
        /^(07|\+2547)[0-9]{8}$/,
        "Please enter a valid Kenyan phone number."
      ),
  });
  const [theintialvalue, settheIntialvalue] = useState(() => {
    return {
      phone: "",
    };
  });

  const submitHandler = (submitdata) => {
    setLoading(true);
    axiosInstance
      .post("/subscribers", { phoneNumber: submitdata.phone }, {})
      .then(async (res) => {
        await axiosInstance.post("/send-email", {
          email: `threedoltswebsite@gmail.com`,
          subject: "Yoo.. New Subscriber",
          message: `<!DOCTYPE html>
          <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
          <head>
              <meta charset="utf-8"> <!-- utf-8 works for most cases -->
              <meta name="viewport" content="width=device-width"> <!-- Forcing initial-scale shouldn't be necessary -->
              <meta http-equiv="X-UA-Compatible" content="IE=edge"> <!-- Use the latest (edge) version of IE rendering engine -->
              <meta name="x-apple-disable-message-reformatting">  <!-- Disable auto-scale in iOS 10 Mail entirely -->
              <title></title> <!-- The title tag shows in email notifications, like Android 4.4. -->
  
              <link href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700" rel="stylesheet">
  
              <!-- CSS Reset : BEGIN -->
              <style>
  
                  /* What it does: Remove spaces around the email design added by some email clients. */
                  /* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
                  html,
          body {
              margin: 0 auto !important;
              padding: 0 !important;
              height: 100% !important;
              width: 100% !important;
              background: #f1f1f1;
          }
  
          /* What it does: Stops email clients resizing small text. */
          * {
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%;
          }
  
          /* What it does: Centers email on Android 4.4 */
          div[style*="margin: 16px 0"] {
              margin: 0 !important;
          }
  
          /* What it does: Stops Outlook from adding extra spacing to tables. */
          table,
          td {
              mso-table-lspace: 0pt !important;
              mso-table-rspace: 0pt !important;
          }
  
          /* What it does: Fixes webkit padding issue. */
          table {
              border-spacing: 0 !important;
              border-collapse: collapse !important;
              table-layout: fixed !important;
              margin: 0 auto !important;
          }
  
          /* What it does: Uses a better rendering method when resizing images in IE. */
          img {
              -ms-interpolation-mode:bicubic;
          }
  
          /* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
          a {
              text-decoration: none;
          }
  
          /* What it does: A work-around for email clients meddling in triggered links. */
          *[x-apple-data-detectors],  /* iOS */
          .unstyle-auto-detected-links *,
          .aBn {
              border-bottom: 0 !important;
              cursor: default !important;
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
          }
  
          /* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
          .a6S {
              display: none !important;
              opacity: 0.01 !important;
          }
  
          /* What it does: Prevents Gmail from changing the text color in conversation threads. */
          .im {
              color: inherit !important;
          }
  
          /* If the above doesn't work, add a .g-img class to any image in question. */
          img.g-img + div {
              display: none !important;
          }
  
          /* Create one of these media queries for each additional viewport size you'd like to fix */
  
          /* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
          @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
              u ~ div .email-container {
                  min-width: 320px !important;
              }
          }
          /* iPhone 6, 6S, 7, 8, and X */
          @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
              u ~ div .email-container {
                  min-width: 375px !important;
              }
          }
          /* iPhone 6+, 7+, and 8+ */
          @media only screen and (min-device-width: 414px) {
              u ~ div .email-container {
                  min-width: 414px !important;
              }
          }
              </style>
  
              <!-- CSS Reset : END -->
  
              <!-- Progressive Enhancements : BEGIN -->
              <style>
  
                .primary{
            background: #17bebb;
          }
          .bg_white{
            background: #ffffff;
          }
          .bg_light{
            background: #f7fafa;
          }
          .bg_black{
            background: #000000;
          }
          .bg_dark{
            background: rgba(0,0,0,.8);
          }
          .email-section{
            padding:2.5em;
          }
  
          /*BUTTON*/
          .btn{
            padding: 10px 15px;
            display: inline-block;
          }
          .btn.btn-primary{
            border-radius: 5px;
            background: #17bebb;
            color: #ffffff;
          }
          .btn.btn-white{
            border-radius: 5px;
            background: #ffffff;
            color: #000000;
          }
          .btn.btn-white-outline{
            border-radius: 5px;
            background: transparent;
            border: 1px solid #fff;
            color: #fff;
          }
          .btn.btn-black-outline{
            border-radius: 0px;
            background: transparent;
            border: 2px solid #000;
            color: #000;
            font-weight: 700;
          }
          .btn-custom{
            color: rgba(0,0,0,.3);
            text-decoration: underline;
          }
  
          h1,h2,h3,h4,h5,h6{
            font-family: 'Work Sans', sans-serif;
            color: #000000;
            margin-top: 0;
            font-weight: 400;
          }
  
          body{
            font-family: 'Work Sans', sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 1.8;
            color: rgba(0,0,0,.4);
          }
  
          a{
            color: #17bebb;
          }
  
          table{
          }
          /*LOGO*/
  
          .logo h1{
            margin: 0;
          }
          .logo h1 a{
            color: #17bebb;
            font-size: 24px;
            font-weight: 700;
            font-family: 'Work Sans', sans-serif;
          }
  
          /*HERO*/
          .hero{
            position: relative;
            z-index: 0;
          }
  
          .hero .text{
            color: rgba(0,0,0,.3);
          }
          .hero .text h2{
            color: #000;
            font-size: 34px;
            margin-bottom: 15px;
            font-weight: 300;
            line-height: 1.2;
          }
          .hero .text h3{
            font-size: 24px;
            font-weight: 200;
          }
          .hero .text h2 span{
            font-weight: 600;
            color: #000;
          }
  
          /*PRODUCT*/
          .product-entry{
            display: block;
            position: relative;
            float: left;
            padding-top: 20px;
          }
          .product-entry .text{
            width: calc(100% - 125px);
            padding-left: 20px;
          }
          .product-entry .text h3{
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .product-entry .text p{
            margin-top: 0;
          }
          .product-entry img, .product-entry .text{
            float: left;
          }
  
          ul.social{
            padding: 0;
          }
          ul.social li{
            display: inline-block;
            margin-right: 10px;
          }
  
          /*FOOTER*/
  
          .footer{
            border-top: 1px solid rgba(0,0,0,.05);
            color: rgba(0,0,0,.5);
          }
          .footer .heading{
            color: #000;
            font-size: 20px;
          }
          .footer ul{
            margin: 0;
            padding: 0;
          }
          .footer ul li{
            list-style: none;
            margin-bottom: 10px;
          }
          .footer ul li a{
            color: rgba(0,0,0,1);
          }
  
          @media screen and (max-width: 500px) {
  
          }
  
              </style>
  
          </head>
  
          <body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
            <center style="width: 100%; background-color: #f1f1f1;">
              <div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
                &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
              </div>
              <div style="max-width: 600px; margin: 0 auto;" class="email-container">
                <!-- BEGIN BODY -->
                <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                  <tr>
                    <td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td class="logo" style="text-align: left;">
                            <h1><a href="www.ninetyone.co.ke">Niney One</a></h1>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr><!-- end tr -->
                  <tr>
                    <td valign="middle" class="hero bg_white" style="padding: 2em 0 2em 0;">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td style="padding: 0 2.5em; text-align: left;">
                            <div class="text">
                              <h5 style="font-size: 16px;">We have new subscriber.</h5>
                            </div>
                          </td>
                        </tr>
                      </table> 
                    </td>
                  </tr><!-- end tr -->
                  <tr>
                    <table class="bg_white" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                        <th width="80%" style="text-align:left; padding: 0 2.5em; color: #000; padding-bottom: 20px"> <p><a href="www.ninetyone.co.ke" class="btn btn-primary">${submitdata.phone}</a></p></th>
                       
                      </td>     
                    </tr>
                    </table>
                  </tr><!-- end tr -->
                <!-- 1 Column Text + Button : END -->
                </table>
                <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                  <tr>
                    <td valign="middle" class="bg_light footer email-section">
                      <table>
                        <tr>
                          <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                              <tr>
                                <td style="text-align: left; padding-right: 10px;">
                                  <h3 class="heading" style="font-size: 16px;">Payment Method</h3>
                                </td>
                              </tr> 
                            </table>
                          </td>
                          <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                              <tr>
                                <td style="text-align: left; padding-left: 5px; padding-right: 5px;">
                                  <h3 class="heading" style="font-size: 16px;">Delivery Address</h3>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr><!-- end: tr -->
                </table>
  
              </div>
            </center>
          </body>
          </html>
          `,
        });
        setLoading(false);
        settheIntialvalue("");
        dispatch(showToast("Subscribed successfully!"));
      })
      .catch((err) => {
        dispatch(showToast(err?.response?.data.message));
        console.log(err);
      });
    setLoading(false);
  };

  return (
    <>
      <section className="py-3 py-md-5 py-xl-10 bg-light">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
              <h6 className="display-10 mb-2 text-center">
                Sign up for our newsletter and never miss a thing.
              </h6>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
              <Formik
                initialValues={theintialvalue}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={submitHandler}
              >
                {({ errors, touched }) => (
                  <Form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                    <div className="col-12 col-lg-8">
                      <Field
                        name="phone"
                        placeholder="phone number"
                        className="form-control"
                        type="text"
                        id="phone"
                      />{" "}
                      {/* <label htmlFor="phone">phone </label> */}
                      {touched.phone && errors.phone && (
                        <div className="text-danger ms-2 small-font-size">
                          {errors.phone}
                        </div>
                      )}
                      <div
                        id="email-newsletter-help"
                        className="form-text text-center text-lg-start"
                      >
                        We'll never share your phone number with anyone else.
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 text-center text-lg-start">
                      <button
                        type="submit"
                        onClick={submitHandler}
                        disabled={loading}
                        className="btn btn-primary bsb-btn-3xl"
                      >
                        {loading ? "Subscribing..." : "Subscribe"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <footer className="">
        <div className="container ">
          <div className="row footer-top">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
              <img
                src="https://res.cloudinary.com/bramuels/image/upload/v1695878268/logo/LOGO-01_moo9oc.png"
                alt="ourlogo"
                style={{ width: "9rem" }}
              />
              <p className="pt-4 footer-color">
                Ninety One - Bringing the World to Your Doorstep: Your Ultimate
                Online Shopping Destination!
              </p>
              <h6
                className="text-light footer-toggle d-sm-none d-flex "
                onClick={toggleFollowUs}
              >
                Follow Us{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`icon ${
                    showFollowUs ? "icon-rotate" : ""
                  } hover-color-yellow`}
                />
              </h6>{" "}
              <div className={`footer-content ${showFollowUs ? "show" : ""}`}>
                <h6 className="text-light d-none d-sm-block ">Follow Us</h6>
                <div className="social-icons d-flex flex-wrap">
                  <Link
                    to="https://instagram.com/"
                    className="me-4 link-light  hover-color-yellow"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="lg"
                      className="hover-color-yellow"
                    />
                  </Link>
                  <Link to="/" className="me-4 link-light">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      size="lg"
                      className="hover-color-yellow"
                    />
                  </Link>
                  <Link to="/" className="me-4 link-light">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="lg"
                      className="hover-color-yellow"
                    />
                  </Link>
                  <Link to="/" className="me-4 link-light">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      size="lg"
                      className="hover-color-green"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <h6
                className="text-light footer-toggle d-sm-none"
                onClick={toggleHereToHelp}
              >
                HERE TO HELP{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`icon ${
                    showHereToHelp ? "icon-rotate" : ""
                  } hover-color-yellow`}
                />
              </h6>
              <h6 className="text-light d-none d-sm-block">HERE TO HELP</h6>

              <div className={`footer-content ${showHereToHelp ? "show" : ""}`}>
                <div>
                  <p className="pt-1 footer-color">
                    Have a question? You may find an answer in our FAQs. But you
                    can also contact us:
                  </p>
                </div>
                <div>
                  <p className="d-flex">
                    <p>
                      <span className="text-light d-block pt-1">
                        Order by phone
                      </span>

                      <span className="d-block footer-color">
                        Available everyday
                      </span>

                      <span className="contacts">
                        <Link
                          to="tel:+254712012113"
                          className=" hover-color-yellow footer-color"
                        >
                          +2547 12 012 113
                        </Link>
                      </span>
                    </p>
                  </p>
                </div>
                <div>
                  <p className="d-flex">
                    <p>
                      <span className="text-light d-block pt-1">Email Us</span>

                      <span className="d-block footer-color">
                        Get in touch by email
                      </span>

                      <span className="contacts">
                        <Link
                          to="mailto:threedoltswebsite@gmail.com"
                          className=" hover-color-yellow footer-color"
                        >
                          threedoltswebsite@gmail.com
                        </Link>
                      </span>
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <h6
                className="text-light footer-toggle d-sm-none"
                onClick={toggleCustomerService}
              >
                Customer Service{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`icon ${
                    showCustomerService ? "icon-rotate" : ""
                  } hover-color-yellow`}
                />
              </h6>
              <h6 className="text-light d-none d-sm-block ">
                Customer Service
              </h6>

              <div
                className={`footer-content ${
                  showCustomerService ? "show" : ""
                }`}
              >
                <div>
                  <p className="menu-footer pt-3">
                    <Link
                      to="/contact"
                      className="footer-color link hover-color-yellow "
                    >
                      Contact Us
                    </Link>
                  </p>
                  <p className="menu-footer">
                    <Link
                      to="/faq"
                      className="footer-color link hover-color-yellow"
                    >
                      FAQs
                    </Link>
                  </p>
                  <p className="menu-footer">
                    <Link
                      to="/"
                      className="footer-color link hover-color-yellow"
                    >
                      Download App
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="raw">
          <hr className="footer-color" />
          <div className="col-12 col-sm pt-2 pb-3 d-flex justify-content-center justify-content-sm-center text-center footer-color">
            <h6 style={{ fontSize: "11px", marginBottom: "45px" }}>
              © {new Date().getFullYear()} Copyright - Ninety One. All Rights
              Reserved. Design By{" "}
              <a
                href="http://www.threedolts.co.ke"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.threedolts.co.ke
              </a>
            </h6>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
