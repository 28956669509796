import React, { useEffect, useState } from "react";
import axiosInstance from "../../apis/config";
import "./notifications.css";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [products, setProducts] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  //eslint-disable-next-line
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    setShowSpinner(true);
    axiosInstance
      .get("/products", {})
      .then((response) => {
        setProducts(response.data.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(showNextProduct, getRandomTime());
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [currentProductIndex]);

  const showNextProduct = () => {
    setCurrentProductIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getRandomTime = () => {
    return Math.floor(Math.random() * (30000 - 5000 + 1)) + 5000;
  };

  const getRandomTimeString = () => {
    const timeAgo = Math.floor(Math.random() * 60);
    return `${timeAgo} ${timeAgo === 1 ? "minute" : "minutes"} ago`;
  };

  const truncateProductName = (name) => {
    return name?.length > 15 ? `${name.slice(0, 15)}...` : name;
  };

  const handleCloseButtonClick = (e) => {
    e.stopPropagation();
    const notificationToast = e.currentTarget.closest("[data-toast]");
    if (notificationToast) {
      notificationToast.classList.add("closed");
    }
  };
  const navigate = useNavigate();
  const handleGoToDetails = (e) => {
    e.stopPropagation();
    navigate(`product-details/${products[currentProductIndex]._id}`);
  };

  return (
    <div>
      {products.length > 0 && (
        <div
          onClick={(e) => handleGoToDetails(e)}
          style={{ cursor: "pointer" }}
        >
          <div className="notification-toast" data-toast>
            <button
              className="toast-close-btn"
              data-toast-close
              onClick={(e) => handleCloseButtonClick(e)}
            >
              <ion-icon name="close-outline"></ion-icon>
            </button>

            <div className="toast-banner">
              <img
                src={products[currentProductIndex]?.documents[0]?.url}
                alt={products[currentProductIndex]?.name}
                width="80"
                height="70"
              />
            </div>

            <div className="toast-detail">
              <p className="toast-message">Someone just bought</p>

              <p className="toast-title">
                {truncateProductName(products[currentProductIndex]?.name)}
              </p>
              <p className="toast-meta">
                <time datetime={getRandomTimeString()}>
                  {getRandomTimeString()}
                </time>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
