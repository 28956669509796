import { useState } from "react";

// react multi carousel
import Carousel from "react-multi-carousel";

// components
import { LeftArrow, RightArrow } from "../common/customSliderArrows";
import Spinner from "../common/spinner";
import RecentlyViewedProducts from "../common/recentlyViewedProducts";
import TrendingProductCart from "../common/trendingProductCart";

// React multi-carousel breakpoints
const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  large: {
    breakpoint: { max: 1199, min: 1024 },
    items: 5,
  },
  medium: {
    breakpoint: { max: 1023, min: 992 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 991, min: 481 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  extraSmall: {
    breakpoint: { max: 480, min: 375 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  extraExtraSmall: {
    breakpoint: { max: 374, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const RelatedProducts = ({ data }) => {
  const [isDragged, setIsdragged] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  // Disable link pointer events if it's being dragged
  const handleDrag = () => {
    if (!isMouseDown) {
      if (isDragged) setIsdragged(false);
      return;
    }
    setIsdragged(true);
  };

  return data ? (
    <div className="py-1">
      <h2 className="text-center my-2">Related Products</h2>
      <div
        className="ps-3"
        onMouseLeave={() => setIsMouseDown(false)}
        onMouseUp={() => setIsMouseDown(false)}
      >
        <Carousel
          responsive={responsive}
          containerClass="multi-carousel"
          draggable
          swipeable
          partialVisible
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          removeArrowOnDeviceType={["medium", "small", "extraSmall"]}
          customRightArrow={<RightArrow />}
          customLeftArrow={<LeftArrow />}
        >
          {data.map((product) => (
            <div
              key={product._id}
              className="pe-3"
              onMouseDown={() => setIsMouseDown(true)}
              onMouseMove={handleDrag}
            >
              <TrendingProductCart product={product} />
            </div>
          ))}
        </Carousel>
      </div>
      <RecentlyViewedProducts />
    </div>
  ) : (
    <Spinner message={"Bringing the related products..."} />
  );
};

export default RelatedProducts;
