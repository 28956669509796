// components
import MainCarousel from "../../components/home/mainCarousel";
import Trending from "./../../components/home/trending";
import Categories from "./../../components/common/Categories";
import Services from "../../components/home/services";
import Notifications from "../../components/common/Notifications";
import HomeProducts from "../../components/common/homeProducts";
import HomeProductDetails from "../../components/common/homeProductDetails";
import RecentlyViewedProducts from "../../components/common/recentlyViewedProducts";

const Home = () => {
  return (
    <div id="home">
      <MainCarousel />
      <Notifications />
      <Categories />
      <Trending />
      <HomeProducts title={"Latest Products"} />
      <HomeProductDetails />
      <HomeProducts title={"Best Deals"} />
      <RecentlyViewedProducts />
      <Services />
    </div>
  );
};

export default Home;
