const Price = ({ price, discount }) => {
  return (
    <>
      {discount > 0 ? (
        <div className="gap-2 align-items-center">
          <span className="fw-semibold pe-2">
            Ksh. {Number(price.toFixed(2)).toLocaleString()}
          </span>
          <br />
          <span className="color-old-price text-decoration-line-through">
            Ksh.{" "}
            {Number(
              (price + (price * discount) / 100).toFixed(2)
            ).toLocaleString()}
          </span>
        </div>
      ) : (
        <span className="fw-semibold">
          Ksh. {Number(price.toFixed(2)).toLocaleString()}
        </span>
      )}
    </>
  );
};

export default Price;
