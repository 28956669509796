import { useState, useEffect } from "react";
import { useParams } from "react-router";

import jwtDecode from "jwt-decode";

//component
import axiosInstance from "../../apis/config";
import Spinner from "../common/spinner";

//style
import styles from "../../pages/account/account.module.css";
import RatingModal from "./ratingModal";
import StarRating from "../common/starRating";
import { Link, useNavigate } from "react-router-dom";

const AccountOrders = ({ token }) => {
  const [userOrder, setUserOrder] = useState([]);
  const [open, setOpen] = useState(false);

  const [showSpinner, setShowSpinner] = useState(true);
  const { id } = useParams();
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setShowSpinner(true);
    if (jwtDecode(token).role === "user") {
      axiosInstance
        .get(`/users/${id}/orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((res) => {
          setUserOrder(res.data.reverse());
          setShowSpinner(false);
        })
        .catch((error) => console.log(error));
    }
    //eslint-disable-next-line
  }, []);
  const navigate = useNavigate();
  const goToLink = (id) => {
    navigate(`/product-details/${id}`);
  };

  return (
    <div>
      <h2 className={`${styles["text-2xl"]} ${styles.subTitle}`}>
        Order History
      </h2>
      {!showSpinner ? (
        <>
          {userOrder.length > 0 ? (
            userOrder.map((order, index) => {
              let totalPrice = 0;
              order?.items?.forEach((item) => {
                totalPrice += item?.price * item.quantity;
              });
              let progress = 0;
              switch (order?.status) {
                case "pending":
                  progress = 20;
                  break;
                case "processing":
                  progress = 40;
                  break;
                case "dispatched":
                  progress = 60;
                  break;
                case "out for delivery":
                  progress = 80;
                  break;
                case "delivered":
                  progress = 100;
                  break;
                case "cancelled":
                  progress = 0;
                  break;
                case "on hold":
                  progress = 10;
                  break;
                default:
                  progress = 0;
              }
              const collapseId = `collapse-${order._id}`;

              return (
                <>
                  <section class="h-100 gradient-custom my-2">
                    <div class="h-100">
                      <div class="">
                        <div class="">
                          <div class="card" style={{ borderRadius: "10px" }}>
                            <div class="card-header px-4 py-3 d-flex flex-wrap justify-content-between">
                              <h5 class="text-muted mb-0">
                                Thank for your order.
                              </h5>
                              <p
                                className={`text-secondary ${styles.deleverDate} mx-1 mb-0`}
                              >
                                <span>Order Status</span>
                                <span className="mx-2">:</span>
                                <span
                                  style={{
                                    color: `${
                                      order?.status === "pending"
                                        ? "orange"
                                        : order?.status === "processing"
                                        ? "blue"
                                        : order?.status === "dispatched"
                                        ? "green"
                                        : order?.status === "out for delivery"
                                        ? "purple"
                                        : order?.status === "delivered"
                                        ? "purple"
                                        : order?.status === "cancelled"
                                        ? "red"
                                        : order?.status === "on hold"
                                        ? "gray"
                                        : "inherit"
                                    }`,
                                  }}
                                >
                                  {order?.status}
                                </span>
                              </p>
                              <span>
                                {order?.date.slice(0, 16).replace("T", " ")}
                              </span>
                              <span>
                                <button
                                  className={`btn btn-outline-secondary`}
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#${collapseId}`}
                                  aria-expanded={index === 0 ? "true" : "false"}
                                  style={{ fontSize: "12px" }}
                                >
                                  hide/view Order
                                </button>
                              </span>
                            </div>
                            <div
                              id={collapseId}
                              className={`collapse ${
                                index === 0 ? "show" : ""
                              }`}
                            >
                              <div class="card-body p-2">
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                  <p
                                    class="lead fw-normal mb-0"
                                    style={{ color: "#a8729a" }}
                                  >
                                    Order Items
                                  </p>
                                  <p class="small text-muted mb-0">
                                    Order Number :{" "}
                                    {order?._id.substring(0, 11).toUpperCase()}
                                  </p>
                                </div>

                                {order?.items.map((item) => (
                                  <>
                                    <RatingModal
                                      open={open}
                                      onClose={closeModal}
                                      orderId={order?._id}
                                      productId={item.product_id._id}
                                    />
                                    <div class="card shadow-0 border mb-4">
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="d-flex flex-wrap">
                                            <Link
                                              to={`/product-details/${item.product_id._id}`}
                                            >
                                              <img
                                                src={
                                                  item.product_id?.documents[0]
                                                    ?.url
                                                }
                                                className="text-center justify-content-center align-items-center"
                                                width="60"
                                                alt="Phone"
                                              />
                                            </Link>
                                            <p
                                              className="mb-0"
                                              onClick={() =>
                                                goToLink(item.product_id._id)
                                              }
                                              style={{
                                                marginLeft: "15px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <StarRating
                                                rating={item.product_id.ratings}
                                              />

                                              <p
                                                className="mb-0"
                                                style={{ fontSize: "12px" }}
                                              >
                                                (
                                                {
                                                  item.product_id.reviews
                                                    ?.length
                                                }
                                                ) reviews
                                              </p>
                                              <p
                                                className="mb-0"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {item.product_id.discount}% off
                                              </p>
                                            </p>
                                            <p
                                              className="mb-0"
                                              style={{ marginLeft: "15px" }}
                                            >
                                              {!item.isReviewed &&
                                              order?.status === "delivered" ? (
                                                <button
                                                  className="btn"
                                                  style={{
                                                    fontSize: "12px",
                                                    backgroundColor: "gold",
                                                  }}
                                                  onClick={() => openModal()}
                                                >
                                                  rate us
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  **You will be able to rate us
                                                  on delivery
                                                </button>
                                              )}
                                            </p>
                                            <p
                                              className="mb-0"
                                              style={{ marginLeft: "15px" }}
                                            >
                                              {order?.status === "delivered" ? (
                                                <button
                                                  className="btn"
                                                  style={{
                                                    fontSize: "12px",
                                                    backgroundColor:
                                                      "lightgreen",
                                                  }}
                                                  onClick={() =>
                                                    goToLink(
                                                      item.product_id._id
                                                    )
                                                  }
                                                >
                                                  order again
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </p>
                                          </div>
                                          <Link
                                            to={`/product-details/${item.product_id._id}`}
                                            class="col-md-4 d-flex justify-content-start align-items-start"
                                          >
                                            <p class="text-muted">
                                              {item.product_id?.name}
                                            </p>
                                          </Link>
                                          <div class="col-md-2 d-flex">
                                            <p class="text-muted mb-0 small">
                                              <p
                                                className={`text-start text-secondary mb-0`}
                                              >
                                                {item?.product_id?.brand?.name}
                                                <div className="d-flex gap-1">
                                                  <span
                                                    className={`${styles.color} rounded-circle  border-1 d-inline-block`}
                                                    style={{
                                                      backgroundColor:
                                                        item.color,
                                                      marginTop: "3px",
                                                    }}
                                                  ></span>
                                                  <p className="">
                                                    {item.size}
                                                  </p>
                                                </div>
                                              </p>
                                            </p>
                                          </div>
                                          <div class="col-md-2 d-flex">
                                            <p class="text-muted small">
                                              Qty: {item.quantity}
                                            </p>
                                          </div>
                                          <div class="col-md-2 d-flex">
                                            <p class="text-muted mb-0 small">
                                              <strong>
                                                sh.{" "}
                                                {item.price.toLocaleString()}
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}

                                <div class="card shadow-0 border mb-4 ">
                                  <div class="row d-flex align-items-center p-4">
                                    <div class="col-md-2">
                                      <p class="text-muted mb-0 small">
                                        Track Order ({order?.status})
                                      </p>
                                    </div>
                                    <div class="col-md-10">
                                      <div
                                        class="progress"
                                        style={{
                                          height: "6px",
                                          borderRadius: "16px",
                                        }}
                                      >
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: `${progress}%`,
                                            // width: `20%`,
                                            borderRadius: "16px",
                                            backgroundColor: "#a8729a",
                                          }}
                                          aria-valuenow="20"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="order_details">
                                  <div class="d-flex flex-wrap justify-content-between pt-2">
                                    <p class="fw-bold mb-0">Order Details</p>
                                    <p class="text-muted mb-0">
                                      <span class="fw-bold me-4">Total</span>{" "}
                                      sh. {totalPrice.toLocaleString()}
                                    </p>
                                  </div>

                                  <div class="d-flex flex-wrap justify-content-between pt-2">
                                    <p class="text-muted mb-0">
                                      Payment :{" "}
                                      <strong>{order?.paymentMethod}</strong>
                                    </p>{" "}
                                    <p class="text-muted mb-0">
                                      <span class="fw-bold me-4">Discount</span>{" "}
                                      sh. 00.00
                                    </p>
                                  </div>

                                  <div class="d-flex flex-wrap justify-content-between mb-1">
                                    <p class="text-muted mb-0">
                                      Address :
                                      <strong>
                                        <br /> {order?.address.apartment},
                                        {order?.address.building},<br />
                                        {order?.address.city},
                                        {order?.address.governorate},
                                        <br />
                                        {order?.address.postalCode},
                                        {order?.address.street}
                                      </strong>
                                    </p>
                                    <p class="text-muted mb-0">
                                      <span class="fw-bold me-4">Delivery</span>{" "}
                                      sh.{" "}
                                      {(
                                        order?.totalPrice - totalPrice
                                      ).toLocaleString()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card-footer border-0 px-4 py-3"
                                style={{
                                  backgroundColor: "#a8729a",
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              >
                                <h5 class="d-flex align-items-center justify-content-end text-white mb-0">
                                  Total paid:{" "}
                                  <span class="mb-0 ms-2">
                                    sh. {order?.totalPrice.toLocaleString()}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              );
            })
          ) : (
            <p className="text-secondary">No orders yet.</p>
          )}
        </>
      ) : (
        <Spinner message={"Bringing you the orders ..."} />
      )}
    </div>
  );
};

export default AccountOrders;
