import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// component
import axiosInstance from "../../apis/config";
import { showToast } from "../../store/slices/toastSlice";

// style
import styles from "./login-register.module.css";

const ForgotPassword = ({ onRegistrationSuccess }) => {
  //eslint-disable-next-line
  const [user, setUser] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (user) => {
    setLoading(true);
    await axiosInstance
      .post("/forgot-password", user)
      .then((response) => {
        setLoading(false);
        dispatch(showToast("Email Sent! Check your Email"));
        setTimeout(() => {
          onRegistrationSuccess();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          showToast("Email not correct/Error Occured. Please try again.")
        );
      });
  };
  return (
    <>
      <Formik
        initialValues={{ ...user }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("Email is required")
            .matches(
              /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              "Email must be a valid email address"
            ),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className={styles.label}>
            <div className="mb-3">
              <label className="mb-1" htmlFor="email">
                Your Email <span>*</span>
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="email"
                type="email"
                placeholder="Please enter your email address"
              />
              {errors.email && touched.email ? (
                <span className="text-danger ms-2 small-font-size">
                  {" "}
                  {errors.email}
                </span>
              ) : null}
            </div>

            <div
              className={`${styles["group-button"]} ${styles["button-submit"]}`}
            >
              <input
                type="submit"
                className={`${loading ? "bg-secondary" : "btn-bg-dark"} ${
                  styles.button
                }`}
                disabled={loading}
                value={`${loading ? "Reseting . . ." : "Reset Password"}`}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
