import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

//component
import axiosInstance from "../../apis/config";
import { counties } from "../../apis/counties";
import { showToast } from "../../store/slices/toastSlice";
import { Modal, Button } from "react-bootstrap";

// style
import styles from "../../pages/account/account.module.css";

const AccountInfo = ({ user, token, setUser }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showBtnSpinner, SetShowBtnSpinner] = useState(false);
  const [showMpesa, setShowMpesa] = useState(false);
  //eslint-disable-next-line
  const [updateUser, setUpdateUser] = useState({
    id: id,
    fullName: user?.fullName,
    email: user?.email,
    phone: user?.phone,
    address: {
      city: user?.address?.city,
      street: user?.address?.street,
      building: user?.address?.building,
      governorate: user?.address?.governorate,
      apartment: user?.address?.apartment,
      postalCode: user?.address?.postalCode,
    },
  });

  const updateUserSubmit = (updateUser) => {
    SetShowBtnSpinner(true);
    axiosInstance
      .patch("/users", updateUser, {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((res) => {
        dispatch(showToast("Account Updated successfully!"));
        SetShowBtnSpinner(false);
        setUser(updateUser);
      })
      .catch((err) => {
        console.log("error", err);
        // handle error, e.g. show error message
        dispatch(showToast("Unable to update, please try again."));
        SetShowBtnSpinner(false);
      });
  };

  const deleteAccount = () => {
    setShowMpesa(true);
  };
  //eslint-disable-next-line
  const [account, setAccount] = useState({
    reason: "",
  });
  const [loading, setLoading] = useState(false);
  const handleDeletion = (account) => {
    setLoading(true);
    console.log("reason", account);
    axiosInstance
      .post("/users/delete-request", {
        account: account,
        id: id,
        email: user?.email,
      })
      .then((response) => {
        setLoading(false);
        dispatch(showToast("Account has been considered for deletion!"));
        setShowMpesa(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message === "Network Error") {
          dispatch(showToast("Network Error. Check your Connection"));
        } else {
          dispatch(showToast(error.message));
        }
      });
  };
  const [selectedCounty, setSelectedCounty] = useState("");

  const handleCountyChange = (e) => {
    const selectedCountyValue = e.target.value;
    setSelectedCounty(selectedCountyValue);
  };
  return (
    <div>
      <h2 className={`${styles["text-2xl"]} ${styles.subTitle}`}>
        Account Information
      </h2>
      <Formik
        initialValues={{
          ...updateUser,
        }}
        validationSchema={Yup.object({
          fullName: Yup.string()
            .required("Full name is required")
            .matches(/^[a-zA-Z ]+$/, "Full name shouldn't have numbers")
            .min(3, "Full name must be at least 3 characters")
            .max(50, "Full name must be less than 50 characters"),
          email: Yup.string()
            .required("Email is required")
            .matches(
              /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              "Email must be a valid email address"
            )
            .test(
              "email-username-length",
              "Sorry, email username must be between 6 and 30 characters long",
              function (value) {
                const username = value.split("@")[0];
                return (
                  username.split("@")[0].length >= 6 && username.length <= 30
                );
              }
            )
            .test("lowercase", "Email must be lowercase", function (value) {
              return value.toLowerCase() === value;
            }),

          phone: Yup.string()
            .optional()
            .nullable()
            .matches(
              /^(07|\+2547)[0-9]{8}$/,
              "Please enter a valid Kenyan phone number."
            ),
          address: Yup.object({
            city: Yup.string().optional().nullable().label("City"),
            street: Yup.string().optional().nullable().label("Street"),
            building: Yup.string().optional().nullable().label("Building"),
            governorate: Yup.string()
              .optional()
              .nullable()
              .label("Governorate"),
            apartment: Yup.string().optional().nullable().label("Apartment"),
            postalCode: Yup.string().optional().label("Postal Code"),
          })
            .optional()
            .nullable()
            .label("Address"),
        })}
        onSubmit={updateUserSubmit}
      >
        {({ errors, touched }) => (
          <>
            <Form className={styles.label}>
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="fullName">
                  Full Name
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="fullName"
                  type="text"
                  id="fullName"
                  placeholder="Please enter your fullName"
                />
                {errors.fullName && touched.fullName ? (
                  <span className="text-danger ms-2">{errors.fullName}</span>
                ) : null}
              </div>
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="email">
                  Email
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="email"
                  type="email"
                  id="email"
                  placeholder="Please enter a valid email address"
                />
                {errors.email && touched.email ? (
                  <span className="text-danger ms-2">{errors.email}</span>
                ) : null}
              </div>
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="phone">
                  Phone Number
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="phone"
                  type="text"
                  id="phone"
                  placeholder="Please enter your phone number"
                />
                {errors?.phone && touched?.phone ? (
                  <span className="text-danger ms-2">{errors.phone}</span>
                ) : null}
              </div>
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="governorate">
                  County
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="address.governorate"
                  type="text"
                  id="governorate"
                  as="select"
                  onClick={(e) => handleCountyChange(e)}
                >
                  <option value="" id="0">
                    Select a county
                  </option>
                  {counties.map((county) => (
                    <option
                      key={county.number}
                      id={county.number}
                      value={county.name}
                      onClick={() => handleCountyChange(county.name)}
                    >
                      {county.name}
                    </option>
                  ))}
                </Field>
                {errors.address?.governorate &&
                touched?.address?.governorate ? (
                  <span className="text-danger ms-2">
                    {errors.address.governorate}
                  </span>
                ) : null}
              </div>
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="city">
                  City
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="address.city"
                  type="text"
                  id="city"
                  as="select"
                >
                  <option value="">Select a constituency</option>
                  {counties
                    .find((county) => county.name === selectedCounty)
                    ?.constituencies.map((constituency) => (
                      <option key={constituency.code} value={constituency.name}>
                        {constituency.name}
                      </option>
                    ))}
                </Field>
                {errors.address?.city && touched?.address?.city ? (
                  <span className="text-danger ms-2">
                    {errors.address.city}
                  </span>
                ) : null}
              </div>

              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="street">
                  Street or Road
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="address.street"
                  type="text"
                  id="street"
                  placeholder="Please enter your street address"
                />
                {errors.address?.street && touched?.address?.street ? (
                  <span className="text-danger ms-2">
                    {errors.address.street}
                  </span>
                ) : null}
              </div>

              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="building">
                  Town
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="address.building"
                  type="string"
                  id="building"
                  placeholder="Please enter your town"
                />
                {errors.address?.building && touched?.address?.building ? (
                  <span className="text-danger ms-2">
                    {errors.address.building}
                  </span>
                ) : null}
              </div>

              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                <label className="mb-1" htmlFor="apartment">
                  Road or Apartment
                </label>
                <Field
                  className={`form-control ${styles.input}`}
                  name="address.apartment"
                  type="text"
                  id="apartment"
                  placeholder="Please enter your apartment"
                />
                {errors.address?.apartment && touched?.address?.apartment ? (
                  <span className="text-danger ms-2">
                    {errors.address.apartment}
                  </span>
                ) : null}
              </div>

              {/* <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="postalCode">
                Postal Code
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="address.postalCode"
                type="text"
                id="postalCode"
                placeholder="Please enter your postal Code"
              />
              {errors.address?.postalCode && touched?.address?.postalCode ? (
                <span className="text-danger ms-2">
                  {errors.address.postalCode}
                </span>
              ) : null}
            </div> */}
              <div className={`mb-4 ${styles["max-w-xl"]}`}>
                {!showBtnSpinner ? (
                  <input
                    type="submit"
                    className={`btn-bg-dark text-center ${styles.button}`}
                    value="Update account"
                  />
                ) : (
                  <button
                    type="button"
                    className={`btn-bg-dark text-center ${styles.button}`}
                  >
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                )}
              </div>
            </Form>
            <div className="d-flex gap-2">
              <button className="mt-2 btn btn-secondary">
                Account Status :
              </button>
              <button
                onClick={deleteAccount}
                className="mt-2 btn btn-success text-center"
              >
                Active
              </button>
              <button
                onClick={deleteAccount}
                className="mt-2 btn btn-danger text-center"
              >
                Delete Account
              </button>
            </div>
          </>
        )}
      </Formik>
      <Formik
        initialValues={{ ...account }}
        validationSchema={Yup.object({
          reason: Yup.string().required("Please tell us why"),
        })}
        onSubmit={handleDeletion}
      >
        {({ errors, touched }) => (
          <>
            {showMpesa && (
              <Modal
                show={showMpesa}
                onHide={() => setShowMpesa(false)}
                style={{ backdropFilter: "blur(2px)" }}
              >
                <Form className={styles.label}>
                  <Modal.Header closeButton>
                    <Modal.Title>Accont Deletion Process</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Please tell us why.</p>
                    <div className={styles.passwordInputWrapper}>
                      <Field
                        as="textarea"
                        className={`form-control my-2 ${styles.input}`}
                        name="reason"
                        type="text"
                        id="reason"
                        placeholder="Please tell us the reason you are leaving"
                      />
                      {errors.reason && touched.reason ? (
                        <span className="text-danger ms-2 small-font-size">
                          {" "}
                          {errors.reason}
                        </span>
                      ) : null}
                    </div>
                    <p className="" style={{ fontSize: "11px" }}>
                      (Disclaimer: keep in mind once account is deleted cannot
                      be recovered. Not even by Ninety One)
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowMpesa(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      // onClick={() => {
                      //   handleDeletion();
                      // }}
                    >
                      {loading ? "loading..." : "Delete"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default AccountInfo;
