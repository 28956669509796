import React, { useEffect, useState } from "react";
import "./ratingModal.css";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axiosInstance from "../../apis/config";
import { showToast } from "../../store/slices/toastSlice";
import { useDispatch } from "react-redux";

const RatingModal = ({ open, onClose, orderId, productId }) => {
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  let token = localStorage.getItem("userToken");

  useEffect(() => {
    if (!token) {
      return;
    }
    axiosInstance
      .get(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, [open]);

  const handleInputChange = (e) => {
    setReviewText(e.target.value);
  };

  const handleSubmit = () => {
    if (rating === null) {
      dispatch(showToast("Please give us a star!"));
    } else if (reviewText === "") {
      dispatch(showToast("Please write a review!"));
    } else {
      setLoading(true);
      axiosInstance
        .put(
          `/products/create-review`,
          {
            userId: id,
            userName: user.fullName,
            productId: productId,
            orderId: orderId,
            rating: rating,
            comment: reviewText,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-access-token": token,
            },
          }
        )
        .then((res) => {
          dispatch(showToast("Review Submitted successfully!"));
          setReviewText("");
          setRating(null);
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(showToast("Error occurred! Please try again"));
        });
    }
  };

  return (
    <>
      {open && (
        <div className={`rating-modal fade show d-block modal appear`}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content container">
              <div className="modal-header row border-0">
                <h6 className="col-6 title">Give Us A rating</h6>

                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: "24px" }}
                  className="cancel btn-close"
                  onClick={() => onClose()}
                />
                <div className="flex w-full ml-2 pt-1">
                  {[1, 2, 3, 4, 5].map((i) =>
                    rating >= i ? (
                      <AiFillStar
                        key={i}
                        className="mr-1 cursor-pointer"
                        color="rgb(246,186,0)"
                        size={25}
                        onClick={() => setRating(i)}
                      />
                    ) : (
                      <AiOutlineStar
                        key={i}
                        className="mr-1 cursor-pointer"
                        color="rgb(246,186,0)"
                        size={25}
                        onClick={() => setRating(i)}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="modal-body pt-1">
                <textarea
                  placeholder="Write your review here..."
                  value={reviewText}
                  onChange={handleInputChange}
                  className="form-control mb-2"
                />

                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submiting ..." : "Submit Review"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RatingModal;
