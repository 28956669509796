import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../apis/config";
import styles from "../../../pages/account/account.module.css";
import StarRating from "../../common/starRating";

const OrdersDetails = () => {
  const { id } = useParams();
  let token = localStorage.getItem("userToken");
  const [order, setOrder] = useState();
  const [newStatus, setNewStatus] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("sp order", order);

  useEffect(() => {
    if (!token) {
      return;
    }
    axiosInstance
      .get(`/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      })
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);
  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const updateStatus = () => {
    setLoading(true);
    axiosInstance
      .put(
        `/orders/${id}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-access-token": token,
          },
        }
      )
      .then(async (res) => {
        await axiosInstance.post("/send-email", {
          email: `${order?.userId.email}`,
          subject: "Order Status Updated",
          message: `<!DOCTYPE html>
          <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
          <head>
              <meta charset="utf-8"> <!-- utf-8 works for most cases -->
              <meta name="viewport" content="width=device-width"> <!-- Forcing initial-scale shouldn't be necessary -->
              <meta http-equiv="X-UA-Compatible" content="IE=edge"> <!-- Use the latest (edge) version of IE rendering engine -->
              <meta name="x-apple-disable-message-reformatting">  <!-- Disable auto-scale in iOS 10 Mail entirely -->
              <title></title> <!-- The title tag shows in email notifications, like Android 4.4. -->
  
              <link href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700" rel="stylesheet">
  
              <!-- CSS Reset : BEGIN -->
              <style>
  
                  /* What it does: Remove spaces around the email design added by some email clients. */
                  /* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
                  html,
          body {
              margin: 0 auto !important;
              padding: 0 !important;
              height: 100% !important;
              width: 100% !important;
              background: #f1f1f1;
          }
  
          /* What it does: Stops email clients resizing small text. */
          * {
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%;
          }
  
          /* What it does: Centers email on Android 4.4 */
          div[style*="margin: 16px 0"] {
              margin: 0 !important;
          }
  
          /* What it does: Stops Outlook from adding extra spacing to tables. */
          table,
          td {
              mso-table-lspace: 0pt !important;
              mso-table-rspace: 0pt !important;
          }
  
          /* What it does: Fixes webkit padding issue. */
          table {
              border-spacing: 0 !important;
              border-collapse: collapse !important;
              table-layout: fixed !important;
              margin: 0 auto !important;
          }
  
          /* What it does: Uses a better rendering method when resizing images in IE. */
          img {
              -ms-interpolation-mode:bicubic;
          }
  
          /* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
          a {
              text-decoration: none;
          }
  
          /* What it does: A work-around for email clients meddling in triggered links. */
          *[x-apple-data-detectors],  /* iOS */
          .unstyle-auto-detected-links *,
          .aBn {
              border-bottom: 0 !important;
              cursor: default !important;
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
          }
  
          /* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
          .a6S {
              display: none !important;
              opacity: 0.01 !important;
          }
  
          /* What it does: Prevents Gmail from changing the text color in conversation threads. */
          .im {
              color: inherit !important;
          }
  
          /* If the above doesn't work, add a .g-img class to any image in question. */
          img.g-img + div {
              display: none !important;
          }
  
          /* Create one of these media queries for each additional viewport size you'd like to fix */
  
          /* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
          @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
              u ~ div .email-container {
                  min-width: 320px !important;
              }
          }
          /* iPhone 6, 6S, 7, 8, and X */
          @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
              u ~ div .email-container {
                  min-width: 375px !important;
              }
          }
          /* iPhone 6+, 7+, and 8+ */
          @media only screen and (min-device-width: 414px) {
              u ~ div .email-container {
                  min-width: 414px !important;
              }
          }
              </style>
  
              <!-- CSS Reset : END -->
  
              <!-- Progressive Enhancements : BEGIN -->
              <style>
  
                .primary{
            background: #17bebb;
          }
          .bg_white{
            background: #ffffff;
          }
          .bg_light{
            background: #f7fafa;
          }
          .bg_black{
            background: #000000;
          }
          .bg_dark{
            background: rgba(0,0,0,.8);
          }
          .email-section{
            padding:2.5em;
          }
  
          /*BUTTON*/
          .btn{
            padding: 10px 15px;
            display: inline-block;
          }
          .btn.btn-primary{
            border-radius: 5px;
            background: #17bebb;
            color: #ffffff;
          }
          .btn.btn-white{
            border-radius: 5px;
            background: #ffffff;
            color: #000000;
          }
          .btn.btn-white-outline{
            border-radius: 5px;
            background: transparent;
            border: 1px solid #fff;
            color: #fff;
          }
          .btn.btn-black-outline{
            border-radius: 0px;
            background: transparent;
            border: 2px solid #000;
            color: #000;
            font-weight: 700;
          }
          .btn-custom{
            color: rgba(0,0,0,.3);
            text-decoration: underline;
          }
  
          h1,h2,h3,h4,h5,h6{
            font-family: 'Work Sans', sans-serif;
            color: #000000;
            margin-top: 0;
            font-weight: 400;
          }
  
          body{
            font-family: 'Work Sans', sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 1.8;
            color: rgba(0,0,0,.4);
          }
  
          a{
            color: #17bebb;
          }
  
          table{
          }
          /*LOGO*/
  
          .logo h1{
            margin: 0;
          }
          .logo h1 a{
            color: #17bebb;
            font-size: 24px;
            font-weight: 700;
            font-family: 'Work Sans', sans-serif;
          }
  
          /*HERO*/
          .hero{
            position: relative;
            z-index: 0;
          }
  
          .hero .text{
            color: rgba(0,0,0,.3);
          }
          .hero .text h2{
            color: #000;
            font-size: 34px;
            margin-bottom: 15px;
            font-weight: 300;
            line-height: 1.2;
          }
          .hero .text h3{
            font-size: 24px;
            font-weight: 200;
          }
          .hero .text h2 span{
            font-weight: 600;
            color: #000;
          }
  
          /*PRODUCT*/
          .product-entry{
            display: block;
            position: relative;
            float: left;
            padding-top: 20px;
          }
          .product-entry .text{
            width: calc(100% - 125px);
            padding-left: 20px;
          }
          .product-entry .text h3{
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .product-entry .text p{
            margin-top: 0;
          }
          .product-entry img, .product-entry .text{
            float: left;
          }
  
          ul.social{
            padding: 0;
          }
          ul.social li{
            display: inline-block;
            margin-right: 10px;
          }
  
          /*FOOTER*/
  
          .footer{
            border-top: 1px solid rgba(0,0,0,.05);
            color: rgba(0,0,0,.5);
          }
          .footer .heading{
            color: #000;
            font-size: 20px;
          }
          .footer ul{
            margin: 0;
            padding: 0;
          }
          .footer ul li{
            list-style: none;
            margin-bottom: 10px;
          }
          .footer ul li a{
            color: rgba(0,0,0,1);
          }
  
          @media screen and (max-width: 500px) {
  
          }
  
              </style>
  
          </head>
  
          <body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
            <center style="width: 100%; background-color: #f1f1f1;">
              <div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
                &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
              </div>
              <div style="max-width: 600px; margin: 0 auto;" class="email-container">
                <!-- BEGIN BODY -->
                <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                  <tr>
                    <td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td class="logo" style="text-align: left;">
                            <h1><a href="www.ninetyone.co.ke">Niney One</a></h1>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr><!-- end tr -->
                  <tr>
                    <td valign="middle" class="hero bg_white" style="padding: 2em 0 2em 0;">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td style="padding: 0 2.5em; text-align: left;">
                            <div class="text">
                              <h5 style="font-size: 16px;">Your Order Status has been Updated.</h5>
                              <p>Order No: ${res.data._id
                                ?.substring(0, 11)
                                .toUpperCase()}</p>
                            </div>
                          </td>
                        </tr>
                      </table> 
                    </td>
                  </tr><!-- end tr -->
                  <tr>
                    <table class="bg_white" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                        <th width="80%" style="text-align:left; padding: 0 2.5em; color: #000; padding-bottom: 20px">Your Order status has Now been Updated to:  <p><a href="www.ninetyone.co.ke/account/${
                          order?.userId._id
                        }" class="btn btn-primary">${newStatus}</a></p></th>
                       
                      </td>     
                    </tr>
                    </table>
                  </tr><!-- end tr -->
                <!-- 1 Column Text + Button : END -->
                </table>
                <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                  <tr>
                    <td valign="middle" class="bg_light footer email-section">
                      <table>
                        <tr>
                          <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                              <tr>
                                <td style="text-align: left; padding-right: 10px;">
                                  <h3 class="heading" style="font-size: 16px;">Payment Method</h3>
                                  <p>${res.data.paymentMethod}</p>
                                </td>
                              </tr> 
                            </table>
                          </td>
                          <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                              <tr>
                                <td style="text-align: left; padding-left: 5px; padding-right: 5px;">
                                  <h3 class="heading" style="font-size: 16px;">Delivery Address</h3>
                                  <ul>
                                    <li><span class="text">${
                                      res.data.address.city
                                    }, ${res.data.address.governorate}, ${
            res.data.address.apartment
          }, ${res.data.address.building}, ${
            res.data.address.street
          }</span></li>
                                    <li><span class="text">${
                                      res.data.phone
                                    }</span></a></li>
                                  </ul>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr><!-- end: tr -->
                </table>
  
              </div>
            </center>
          </body>
          </html>
          `,
        });
        setOrder({ ...order, status: newStatus });
        setNewStatus("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  let totalPrice = 0;
  order?.items.forEach((item) => {
    totalPrice += item?.price * item.quantity;
  });

  let progress = 0;
  switch (order?.status) {
    case "pending":
      progress = 20;
      break;
    case "processing":
      progress = 40;
      break;
    case "dispatched":
      progress = 60;
      break;
    case "out for delivery":
      progress = 80;
      break;
    case "delivered":
      progress = 100;
      break;
    case "cancelled":
      progress = 0;
      break;
    case "on hold":
      progress = 10;
      break;
    default:
      progress = 0;
  }

  return (
    <div>
      <section class="h-100 gradient-custom">
        <div class="container py-5 h-100">
          <div class="">
            <div class="">
              <div class="card" style={{ borderRadius: "10px" }}>
                <div class="card-header px-4 py-3 d-flex flex-wrap justify-content-between">
                  <h5 class="text-muted mb-0">
                    Order for,{" "}
                    <span style={{ color: "#a8729a" }}>
                      {order?.userId.fullName} ({order?.phone})
                    </span>
                    <span className="" style={{ marginLeft: "3px" }}>
                      {order?.userId.email.substring(0, 5)}***
                      {order?.userId.email.substring(
                        order?.userId.email.indexOf("@")
                      )}
                    </span>
                  </h5>
                  <p className={`text-secondary ${styles.deleverDate} mx-1`}>
                    <span>Order Status</span>
                    <span className="mx-2">:</span>
                    <span className={styles.deliever}>{order?.status} </span>
                  </p>
                  <span>{order?.date.slice(0, 16).replace("T", " ")}</span>
                </div>
                <div class="card-body p-4">
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <p class="lead fw-normal mb-0" style={{ color: "#a8729a" }}>
                      Order Items
                    </p>
                    <p class="small text-muted mb-0">
                      Order Number : {order?._id.substring(0, 11).toUpperCase()}
                    </p>
                  </div>
                  {order?.items.map((item) => (
                    <div class="card shadow-0 border mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="d-flex">
                            <img
                              src={item.product_id?.documents[0]?.url}
                              className="text-center justify-content-center align-items-center"
                              width="60"
                              alt="Phone"
                            />
                            <p className="mb-0" style={{ marginLeft: "15px" }}>
                              <StarRating rating={item.product_id.ratings} />
                              <p className="mb-0" style={{ fontSize: "12px" }}>
                                ({item.product_id.reviews?.length}) reviews{" "}
                              </p>
                              <p className="mb-0" style={{ fontSize: "12px" }}>
                                {item.product_id.discount}% off
                              </p>
                            </p>
                          </div>
                          <div class="col-md-4 d-flex justify-content-start align-items-start">
                            <p class="text-muted">{item.product_id?.name}</p>
                          </div>
                          <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                            <p class="text-muted mb-0 small">
                              <p className={`text-start text-secondary`}>
                                {item?.product_id?.brand?.name}
                                <div className="d-flex gap-1">
                                  <span
                                    className={`${styles.color} rounded-circle  border-1 d-inline-block`}
                                    style={{
                                      backgroundColor: item.color,
                                      marginTop: "3px",
                                    }}
                                  ></span>
                                  <p className="text-center">{item.size}</p>
                                </div>
                              </p>
                            </p>
                          </div>
                          <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                            <p class="text-muted mb-0 small">
                              Qty: {item.quantity}
                            </p>
                          </div>
                          <div class="col-md-2 text-center d-flex justify-content-center align-items-center">
                            <p class="text-muted mb-0 small">
                              sh. {item.price.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div class="card shadow-0 border mb-4">
                    <div class="row d-flex align-items-center p-3">
                      <div class="col-md-2">
                        <p class="text-muted mb-0 small">Track Order</p>
                      </div>
                      <div class="col-md-10">
                        <div
                          class="progress"
                          style={{ height: "6px", borderRadius: "16px" }}
                        >
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${progress}%`,
                              borderRadius: "16px",
                              backgroundColor: "#a8729a",
                            }}
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="d-flex justify-content-around mb-1">
                          <p class="text-muted mt-1 mb-0 small ms-xl-5">
                            {order?.status}
                          </p>
                          <p class="text-muted mt-1 mb-0 small ms-xl-5">
                            Delivered
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card shadow-0 border mb-4">
                    <div
                      className="d-flex"
                      style={{
                        margin: "10px",
                        padding: "5px",
                      }}
                    >
                      <label
                        for="status"
                        className="mr-2 mb-2 justify-content-center text-center"
                      >
                        Change Status:
                      </label>
                      <select
                        class=" mr-2"
                        value={newStatus}
                        onChange={handleStatusChange}
                        id="status"
                        aria-label="Default select example"
                        style={{
                          padding: "0.5rem 1rem",
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          margin: "5px",
                        }}
                      >
                        <option value="">Update Status</option>
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="dispatched">Dispatched</option>
                        <option value="out for delivery">
                          Out for Delivery
                        </option>
                        <option value="delivered">Delivered</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="on hold">On Hold</option>
                      </select>
                      <button
                        type="button"
                        class="btn btn-light"
                        style={{ marginLeft: "2px" }}
                        onClick={updateStatus}
                      >
                        {loading ? "updating..." : "Update"}
                      </button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between pt-2">
                    <p class="fw-bold mb-0">Order Details</p>
                    <p class="text-muted mb-0">
                      <span class="fw-bold me-4">Total</span> sh.{" "}
                      {totalPrice.toLocaleString()}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pt-2">
                    <p class="text-muted mb-0">
                      Payment Method : <strong>{order?.paymentMethod}</strong>
                    </p>{" "}
                    <p class="text-muted mb-0">
                      <span class="fw-bold me-4">Discount</span> sh. 00.00
                    </p>
                  </div>

                  <div class="d-flex justify-content-between mb-1">
                    <p class="text-muted mb-0">
                      Address :
                      <strong>
                        <br /> {order?.address.apartment},
                        {order?.address.building},<br />
                        {order?.address.city},{order?.address.governorate},
                        <br />
                        {order?.address.postalCode},{order?.address.street}
                        {order?.userId.fullName}, ({order?.phone}), <br />
                        {order?.userId.email}
                      </strong>
                    </p>
                    <p class="text-muted mb-0">
                      <span class="fw-bold me-4">Delivery Charges</span> sh.{" "}
                      {(order?.totalPrice - totalPrice).toLocaleString()}
                    </p>
                  </div>
                </div>
                <div
                  class="card-footer border-0 px-4 py-3"
                  style={{
                    backgroundColor: "#a8729a",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <h5 class="d-flex align-items-center justify-content-end text-white mb-0">
                    Total paid:{" "}
                    <span class="mb-0 ms-2">
                      sh. {order?.totalPrice.toLocaleString()}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrdersDetails;
