//form validation
import { useState } from "react";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

const CategoryForm = ({ initialValues, onSubmit }) => {
  const [imageUrl, setImageUrl] = useState([]);

  console.log("imageUrl", imageUrl);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category Name is required"),
    // image: Yup.mixed().test(
    //   "fileType",
    //   "Invalid file, Images only",
    //   (value) => {
    //     if (value && value.type) {
    //       return value.type.startsWith("image");
    //     }
    //     return true;
    //   }
    // ),
  });
  const MAX_FILE_SIZE = 200 * 1024; // 200KB

  // const onImageInput = (e) => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name" className="pb-2">
              Category Name
            </label>
            <Field
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Enter category name"
              autoComplete="off"
            />
            {errors.name && touched.name ? (
              <div className="text-danger">{errors.name}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="image" className="form-label pt-4">
              {initialValues.image ? "Update" : "Upload"} Category Image
            </label>
            <div>
              {imageUrl?.map((image, index) => (
                <img
                  src={image}
                  alt={`Current category: ${values.name}`}
                  className="img-thumbnail mb-2"
                  width="100"
                  name="image"
                />
              ))}{" "}
            </div>

            <input
              type="file"
              className="form-control"
              id="image"
              name="image"
              onChange={(e) => {
                let files = Array.from(e.target.files);
                files.forEach((file) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                      const canvas = document.createElement("canvas");
                      const ctx = canvas.getContext("2d");

                      const MAX_WIDTH = 800; // Max width for the image
                      const MAX_HEIGHT = 600; // Max height for the image

                      let width = image.width;
                      let height = image.height;

                      // Resize the image if it exceeds the max width or height
                      if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                        const aspectRatio = width / height;
                        if (width > height) {
                          width = MAX_WIDTH;
                          height = width / aspectRatio;
                        } else {
                          height = MAX_HEIGHT;
                          width = height * aspectRatio;
                        }
                      }

                      canvas.width = width;
                      canvas.height = height;

                      ctx.drawImage(image, 0, 0, width, height);

                      canvas.toBlob(
                        (blob) => {
                          if (blob.size <= MAX_FILE_SIZE) {
                            setImageUrl((old) => [...old, canvas.toDataURL()]);
                            setFieldValue(
                              "image",
                              [...imageUrl, canvas.toDataURL()],
                              false
                            );
                          } else {
                            console.log("Image size exceeds 200KB");
                          }
                        },
                        "image/jpeg",
                        0.6
                      ); // 0.6 is the image quality (adjust as needed)
                    };
                  };
                  reader.readAsDataURL(file);
                });
              }}
            />
            {errors.image && touched.image ? (
              <div className="text-danger">{errors.image}</div>
            ) : null}
          </div>
          <button type="submit" className="btn btn-primary mt-4">
            {initialValues.name ? "Update" : "Add"} Category
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CategoryForm;
