import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import { IoMdCloseCircleOutline } from "react-icons/io";

const PopoverContent = ({ show, targetRef, message, place, onClose }) => {
  return (
    <Overlay
      show={show}
      target={targetRef.current}
      placement={place}
      containerPadding={20}
    >
      <Popover id="popover-basic">
        <Popover.Header as="h3">
          <div className="d-flex justify-content-between">
            {" "}
            <p>Ninety One</p>{" "}
            <p
              onClick={onClose}
              style={{ cursor: "pointer", color: "#838080" }}
            >
              <IoMdCloseCircleOutline />
            </p>
          </div>
        </Popover.Header>

        <Popover.Body>{message}</Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default PopoverContent;
