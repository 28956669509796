// style
import style from "../../pages/productDetails/productDetails.module.css";
import StarRating from "../common/starRating";

const Price = ({ price, discount, rating, reviews }) => {
  return (
    <>
      <div class="d-flex" style={{ marginBottom: 0 }}>
        <p className="mb-0">
          <StarRating
            rating={rating}
            className="mb-0"
            style={{ marginBottom: 0 }}
          />
        </p>
        <p style={{ marginLeft: "5px", fontSize: "12px" }}>
          ({reviews}) reviews
        </p>
      </div>
      {discount > 0 && (
        <div className="d-flex">
          <span className={`color-yellow ${style.discount}`}>
            Discount: ({discount}%)
          </span>
          <span
            className={`p-1 border rounded`}
            style={{
              color: "#ffff",
              marginLeft: "3px",
              fontSize: "13px",
              background: "#57af57",
              marginBottom: "3px",
            }}
          >
            save sh.{" "}
            {Number((discount / 100) * price)
              .toFixed()
              .toLocaleString()}
          </span>
        </div>
      )}
      {discount > 0 ? (
        <div className="d-flex gap-2 align-items-center">
          <span className="color-yellow fw-semibold">
            Ksh. {Number(price.toFixed(2)).toLocaleString()}
          </span>
          <span className="color-old-price text-decoration-line-through">
            Ksh.{" "}
            {Number(
              (price + (price * discount) / 100).toFixed(2)
            ).toLocaleString()}
          </span>
        </div>
      ) : (
        <span className="fw-semibold">
          Ksh. {Number(price.toFixed(2)).toLocaleString()}
        </span>
      )}
    </>
  );
};

export default Price;
