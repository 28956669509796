import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
//
import axiosInstance from "../../apis/config";
import { emptyCart } from "../../functions/cart";
import { showToast } from "../../store/slices/toastSlice.js";

import OrderInfo from "./orderInfo";
import Spinner from "../common/spinner";
import ConfirmPopup from "../common/confirmPopup";
//style
import style from "../../pages/checkout/checkout.module.css";
import { Modal, Button } from "react-bootstrap";
import ShoppingCardComponent from "./ShoppingCardComponent.jsx";
import { toast } from "react-hot-toast";

export default function PaymentMethod() {
  const [showWarning, setShowWarning] = useState(false);
  const [showMpesa, setShowMpesa] = useState(false);
  const [showBtnSpinner, SetShowBtnSpinner] = useState(false);
  const [isAddingOrder, setIsAddingOrder] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm order");
  const [paymentMethod, setPaymentMethod] = useState("notselected");
  const [selected, setSelected] = useState(false);
  const [primierPayment, setPrimierPayment] = useState(0);
  const [mpesaLoading, setMpesaLoading] = useState(false);
  const [mpesaMessage, setMpesaMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");

  //eslint-disable-next-line
  const shippingValue = Number(0.0).toLocaleString();
  // ===========

  const cart = useSelector((state) => state.cart.cart);
  ////

  let totalPrice = 0;
  //eslint-disable-next-line
  const updatedAvailableItems = cart?.items?.filter((item) => {
    const color = item?.color;
    const stock = item?.product_id?.colors.find(
      (c) => c.color === color
    )?.stock;

    if (stock >= item?.quantity) {
      totalPrice += item?.product_id.price * item.quantity;
      return item;
    }
  });
  const shipping = totalPrice >= 5000 ? 0 : 300;
  // const shipping = totalPrice >= 5000 ? 0 : 0;

  // const priceWithShapping = (shipping + +totalPrice + primierPayment).toFixed(
  //   2
  // );
  const [priceWithShapping, setPriceWithShipping] = useState(
    (shipping + +totalPrice).toFixed(2)
  );
  const [totalShapping, setTotalShipping] = useState(shipping.toFixed(2));
  useEffect(() => {
    const newPriceWithShapping = (
      shipping +
      +totalPrice +
      primierPayment
    ).toFixed(2);
    const newShipping = shipping + primierPayment;
    setPriceWithShipping(newPriceWithShapping);
    setTotalShipping(newShipping);
  }, [primierPayment, shipping, totalPrice]);

  const formData = JSON.parse(localStorage.getItem("localFormData"));
  const onConfirmClick = async () => {
    SetShowBtnSpinner(true);
    setShowWarning(false);

    const additionalInfo = {
      address: formData?.address,
      phone: formData?.phone,
      totalPrice: priceWithShapping,
      userId: cart?.user_id,
      items: updatedAvailableItems?.map((item) => ({
        product_id: item?.product_id._id,
        quantity: item.quantity,
        color: item.color,
        size: item.size,
        price: item.product_id.price,
      })),
    };
    const newObjectData = {
      address: formData?.address,
      phone: formData?.phone,
      paymentMethod: paymentMethod,
      ...additionalInfo,
    };
    try {
      dispatch(showToast("Thanks for shopping with us."));
    } catch (error) {
      console.log(error.text);
      dispatch(showToast("Sorry, error occured!"));
    }
    await axiosInstance
      .post(`/orders`, newObjectData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then(async (res) => {
        navigate(`/order-confirmed/${res.data._id}`, { replace: true });
        emptyCart(cart._id);
        setIsAddingOrder(true);
        SetShowBtnSpinner(false);
        //emails send sending
        console.log("response data", res.data);
        try {
          await axiosInstance.post("/send-email", {
            email: formData?.email,
            subject: "Ninety One Order Confirmation",
            message: `
            <!DOCTYPE html>
            <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width"> 
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="x-apple-disable-message-reformatting"> 
                <title></title>
                <link href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700" rel="stylesheet">
                <style>
                    html,body {
                          margin: 0 auto !important;
                          padding: 0 !important;
                          height: 100% !important;
                          width: 100% !important;
                          background: #f1f1f1;
                         }
                    * {
                        -ms-text-size-adjust: 100%;
                        -webkit-text-size-adjust: 100%;
                      }
                    div[style*="margin: 16px 0"] {
                         margin: 0 !important;
                      }
                    table,td {
                      mso-table-lspace: 0pt !important;
                      mso-table-rspace: 0pt !important;
                     }
                    table {
                      border-spacing: 0 !important;
                      border-collapse: collapse !important;
                      table-layout: fixed !important;
                       margin: 0 auto !important;
                      }
                    img {
                      -ms-interpolation-mode:bicubic;
                      }
                    a {
                       text-decoration: none;
                      }
                    *[x-apple-data-detectors],  /* iOS */
                      .unstyle-auto-detected-links *,
                      .aBn {
                          border-bottom: 0 !important;
                          cursor: default !important;
                          color: inherit !important;
                          text-decoration: none !important;
                          font-size: inherit !important;
                          font-family: inherit !important;
                          font-weight: inherit !important;
                          line-height: inherit !important;
                      }
                    .a6S {
                       display: none !important;
                       opacity: 0.01 !important;
                     }
    
                    .im {
                       color: inherit !important;
                    }
    
                    img.g-img + div {
                    display: none !important;
                    }
      
                   @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
                    u ~ div .email-container {
                    min-width: 320px !important;
                    }
                   }
                   @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
                   u ~ div .email-container {
                    min-width: 375px !important;
                   }
                   }
                   @media only screen and (min-device-width: 414px) {
                    u ~ div .email-container {
                      min-width: 414px !important;
                    }
                   }
                </style>
                <style>
    
                   .primary{
                     background: #17bebb;
                    }
                  .bg_white{
                     background: #ffffff;
                  }
                  .bg_light{
                   background: #f7fafa;
                    }
                  .bg_black{
                   background: #000000;
                  }
                  .bg_dark{
                    background: rgba(0,0,0,.8);
                  }
                 .email-section{
                  padding:2.5em;
                  }
    
                  .btn{
                    padding: 10px 15px;
                    display: inline-block;
                  }
                  .btn.btn-primary{
                    border-radius: 5px;
                   background: #17bebb;
                   color: #ffffff;
                  }
                 .btn.btn-white{
                    border-radius: 5px;
                     background: #ffffff;
                   color: #000000;
                    }
                 .btn.btn-white-outline{
                    border-radius: 5px;
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    }
                 .btn.btn-black-outline{
                   border-radius: 0px;
                   background: transparent;
                   border: 2px solid #000;
                   color: #000;
                    font-weight: 700;
                   }
                  .btn-custom{
                    color: rgba(0,0,0,.3);
                   text-decoration: underline;
                   }
                  h1,h2,h3,h4,h5,h6{
                   font-family: 'Work Sans', sans-serif;
                    color: #000000;
                   margin-top: 0;
                    font-weight: 400;
                  }
                  body{
                    font-family: 'Work Sans', sans-serif;
                     font-weight: 400;
                    font-size: 15px;
                    line-height: 1.8;
                  color: rgba(0,0,0,.4);
                   }
                 a{
                    color: #17bebb;
                   }
    
                 table{
                  }
                  .logo h1{
                     margin: 0;
                   }
                  .logo h1 a{
                   color: #17bebb;
                   font-size: 24px;
                   font-weight: 700;
                   font-family: 'Work Sans', sans-serif;
                  }
                  .hero{
                   position: relative;
                   z-index: 0;
                  }
                 .hero .text{
                   color: rgba(0,0,0,.3);
                   }
                  .hero .text h2{
                   color: #000;
                    font-size: 34px;
                   margin-bottom: 15px;
                    font-weight: 300;
                  line-height: 1.2;
                    }
                 .hero .text h3{
                   font-size: 24px;
                    font-weight: 200;
                 }
                 .hero .text h2 span{
                   font-weight: 600;
                     color: #000;
                    }
                  .product-entry{
                      display: block;
                      position: relative;
                       float: left;
                    padding-top: 5px;
                  }
                 .product-entry .text{
                   width: calc(100% - 125px);
                   padding-left: 20px;
                 }
                 .product-entry .text h3{
                   margin-bottom: 0;
                   padding-bottom: 0;
                 }
                 .product-entry .text p{
                   margin-top: 0;
                 }
                 .product-entry img, .product-entry .text{
                   float: left;
                 }
               
                 ul.social{
                   padding: 0;
                 }
                 ul.social li{
                   display: inline-block;
                   margin-right: 10px;
                 }
               
                 /*FOOTER*/
               
                 .footer{
                   border-top: 1px solid rgba(0,0,0,.05);
                   color: rgba(0,0,0,.5);
                 }
                 .footer .heading{
                   color: #000;
                   font-size: 20px;
                 }
                 .footer ul{
                   margin: 0;
                   padding: 0;
                 }
                 .footer ul li{
                   list-style: none;
                   margin-bottom: 10px;
                 }
                 .footer ul li a{
                   color: rgba(0,0,0,1);
                 }
               
                 @media screen and (max-width: 500px) {
  
                 }     
           </style>
        </head>
            <body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
              <center style="width: 100%; background-color: #f1f1f1;">
                <div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
                  &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
                </div>
                <div style="max-width: 600px; margin: 0 auto;" class="email-container">
                  <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                    <tr>
                      <td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td class="logo" style="text-align: left;">
                              <h1><a href="www.ninetyone.co.ke">Niney One</a></h1>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle" class="hero bg_white" style="padding: 2em 0 2em 0;">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td style="padding: 0 2.5em; text-align: left;">
                              <div class="text">
                                <h5 style="font-size: 16px;">Thank you for shopping with us.</h5>
                                <p>Order No: ${res.data._id
                                  ?.substring(0, 11)
                                  .toUpperCase()}</p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <table class="bg_white" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                          <th width="100%" style="text-align:left; padding: 0 2.5em; color: #000; padding-bottom: 20px">Item(s)</th>
                        </tr>
                        ${updatedAvailableItems?.map(
                          (item) => `
                        <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                          <td valign="middle" width="100%" style="padding: 0 1em;">
                           <div class="product-entry">
                            <img src="${
                              item?.product_id?.documents[0]?.url
                            }" alt="" style="width: 40px; max-width: 100px; height: auto; margin-bottom: 20px; display: block;">
                            <div class="text"> 
                              <h3>${item?.product_id?.name}</h3>
                              <span>${
                                item?.product_id?.brand?.name
                                  ? `Brand: ${item?.product_id?.brand?.name}`
                                  : ""
                              }</span> 
                              <span>Qty: ${item?.quantity}</span>
                              <span style="display: flex;">Size & Color: ${
                                item?.size ? `${item?.size}` : ""
                              }    <p style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
                            item?.color
                          }; border: 1px solid black; margin-top: 3px; margin-left: 2px;">
                              </p> </span>
                          
                              <span class="price" style="color: #000;"><strong>sh. ${
                                item?.product_id?.price
                              }</strong></span>
                            </div>
                          </div>  
                          </td>
                        </tr>
                      `
                        )} 
                        <tr><td valign="middle" style="padding: 1em 2.5em;">
                        <p><a href="#" class="btn btn-primary">Total Price: ${priceWithShapping}</a></p>
                          </td></tr>
                      </table>
                    </tr>
                  </table>
                  <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                    <tr>
                      <td valign="middle" class="bg_light footer email-section">
                        <table>
                          <tr>
                            <td valign="top" width="33.333%" style="padding-top: 20px;">
                              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align: left; padding-right: 10px;">
                                    <h3 class="heading" style="font-size: 16px;">Details</h3>
                                    <p>Sub Totals: ${totalPrice}</p>
                                    <p>Shipping: ${totalShapping}</p>
                                    <p>Total Price: ${priceWithShapping}</p>
                                    <p>Payment: ${res.data.paymentMethod}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td valign="top" width="33.333%" style="padding-top: 20px;">
                              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align: left; padding-left: 5px; padding-right: 5px;">
                                    <h3 class="heading" style="font-size: 16px;">Delivery Address</h3>
                                    <ul>
                                      <li><span class="text">${
                                        res.data.address.city
                                      }, ${res.data.address.governorate}, ${
              res.data.address.apartment
            }, ${res.data.address.building}, ${
              res.data.address.street
            }</span></li>
                                      <li><span class="text">${
                                        res.data.phone
                                      }</span></a></li>
                                    </ul>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
    
                </div>
              </center>
            </body>
            </html>
            
            `,
          });
          await axiosInstance.post("/send-email", {
            email: "threedoltswebsite@gmail.com",
            subject: "New Order",
            message: ` 
            <!DOCTYPE html>
            <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width"> 
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="x-apple-disable-message-reformatting"> 
                <title></title>
                <link href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700" rel="stylesheet">
                <style>
                    html,body {
                          margin: 0 auto !important;
                          padding: 0 !important;
                          height: 100% !important;
                          width: 100% !important;
                          background: #f1f1f1;
                         }
                    * {
                        -ms-text-size-adjust: 100%;
                        -webkit-text-size-adjust: 100%;
                      }
                    div[style*="margin: 16px 0"] {
                         margin: 0 !important;
                      }
                    table,td {
                      mso-table-lspace: 0pt !important;
                      mso-table-rspace: 0pt !important;
                     }
                    table {
                      border-spacing: 0 !important;
                      border-collapse: collapse !important;
                      table-layout: fixed !important;
                       margin: 0 auto !important;
                      }
                    img {
                      -ms-interpolation-mode:bicubic;
                      }
                    a {
                       text-decoration: none;
                      }
                    *[x-apple-data-detectors],  /* iOS */
                      .unstyle-auto-detected-links *,
                      .aBn {
                          border-bottom: 0 !important;
                          cursor: default !important;
                          color: inherit !important;
                          text-decoration: none !important;
                          font-size: inherit !important;
                          font-family: inherit !important;
                          font-weight: inherit !important;
                          line-height: inherit !important;
                      }
                    .a6S {
                       display: none !important;
                       opacity: 0.01 !important;
                     }
    
                    .im {
                       color: inherit !important;
                    }
    
                    img.g-img + div {
                    display: none !important;
                    }
      
                   @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
                    u ~ div .email-container {
                    min-width: 320px !important;
                    }
                   }
                   @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
                   u ~ div .email-container {
                    min-width: 375px !important;
                   }
                   }
                   @media only screen and (min-device-width: 414px) {
                    u ~ div .email-container {
                      min-width: 414px !important;
                    }
                   }
                </style>
                <style>
    
                   .primary{
                     background: #17bebb;
                    }
                  .bg_white{
                     background: #ffffff;
                  }
                  .bg_light{
                   background: #f7fafa;
                    }
                  .bg_black{
                   background: #000000;
                  }
                  .bg_dark{
                    background: rgba(0,0,0,.8);
                  }
                 .email-section{
                  padding:2.5em;
                  }
    
                  .btn{
                    padding: 10px 15px;
                    display: inline-block;
                  }
                  .btn.btn-primary{
                    border-radius: 5px;
                   background: #17bebb;
                   color: #ffffff;
                  }
                 .btn.btn-white{
                    border-radius: 5px;
                     background: #ffffff;
                   color: #000000;
                    }
                 .btn.btn-white-outline{
                    border-radius: 5px;
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                    }
                 .btn.btn-black-outline{
                   border-radius: 0px;
                   background: transparent;
                   border: 2px solid #000;
                   color: #000;
                    font-weight: 700;
                   }
                  .btn-custom{
                    color: rgba(0,0,0,.3);
                   text-decoration: underline;
                   }
                  h1,h2,h3,h4,h5,h6{
                   font-family: 'Work Sans', sans-serif;
                    color: #000000;
                   margin-top: 0;
                    font-weight: 400;
                  }
                  body{
                    font-family: 'Work Sans', sans-serif;
                     font-weight: 400;
                    font-size: 15px;
                    line-height: 1.8;
                  color: rgba(0,0,0,.4);
                   }
                 a{
                    color: #17bebb;
                   }
    
                 table{
                  }
                  .logo h1{
                     margin: 0;
                   }
                  .logo h1 a{
                   color: #17bebb;
                   font-size: 24px;
                   font-weight: 700;
                   font-family: 'Work Sans', sans-serif;
                  }
                  .hero{
                   position: relative;
                   z-index: 0;
                  }
                 .hero .text{
                   color: rgba(0,0,0,.3);
                   }
                  .hero .text h2{
                   color: #000;
                    font-size: 34px;
                   margin-bottom: 15px;
                    font-weight: 300;
                  line-height: 1.2;
                    }
                 .hero .text h3{
                   font-size: 24px;
                    font-weight: 200;
                 }
                 .hero .text h2 span{
                   font-weight: 600;
                     color: #000;
                    }
                  .product-entry{
                      display: block;
                      position: relative;
                       float: left;
                    padding-top: 20px;
                  }
                 .product-entry .text{
                   width: calc(100% - 125px);
                   padding-left: 20px;
                 }
                 .product-entry .text h3{
                   margin-bottom: 0;
                   padding-bottom: 0;
                 }
                 .product-entry .text p{
                   margin-top: 0;
                 }
                 .product-entry img, .product-entry .text{
                   float: left;
                 }
               
                 ul.social{
                   padding: 0;
                 }
                 ul.social li{
                   display: inline-block;
                   margin-right: 10px;
                 }
               
                 /*FOOTER*/
               
                 .footer{
                   border-top: 1px solid rgba(0,0,0,.05);
                   color: rgba(0,0,0,.5);
                 }
                 .footer .heading{
                   color: #000;
                   font-size: 20px;
                 }
                 .footer ul{
                   margin: 0;
                   padding: 0;
                 }
                 .footer ul li{
                   list-style: none;
                   margin-bottom: 10px;
                 }
                 .footer ul li a{
                   color: rgba(0,0,0,1);
                 }
               
                 @media screen and (max-width: 500px) {
  
                 }     
           </style>
        </head>
            <body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">
              <center style="width: 100%; background-color: #f1f1f1;">
                <div style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
                  &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
                </div>
                <div style="max-width: 600px; margin: 0 auto;" class="email-container">
                  <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                    <tr>
                      <td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td class="logo" style="text-align: left;">
                              <h1><a href="www.ninetyone.co.ke">Niney One</a></h1>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle" class="hero bg_white" style="padding: 2em 0 2em 0;">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td style="padding: 0 2.5em; text-align: left;">
                              <div class="text">
                                <h5 style="font-size: 16px;">You have a New Order from${
                                  formData?.fullName
                                }, ${formData?.phone}</h5>
                                <p>Order No: ${res.data._id
                                  ?.substring(0, 11)
                                  .toUpperCase()}</p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <table class="bg_white" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                          <th width="100%" style="text-align:left; padding: 0 2.5em; color: #000; padding-bottom: 20px">Item(s)</th>
                        </tr>
                        ${updatedAvailableItems?.map(
                          (item) => `
                        <tr style="border-bottom: 1px solid rgba(0,0,0,.05);">
                          <td valign="middle" width="100%" style="padding: 0 1em;">
                           <div class="product-entry">
                            <img src="${
                              item?.product_id?.documents[0]?.url
                            }" alt="" style="width: 40px; max-width: 100px; height: auto; margin-bottom: 20px; display: block;">
                            <div class="text"> 
                              <h3>${item?.product_id?.name}</h3>
                              <span>Brand: ${
                                item?.product_id?.brand?.name
                              }</span>
                              <span>Qty: ${item?.quantity}</span>
                              <span>Size & Color: ${
                                item?.size ? `${item?.size}` : ""
                              } </span>
                              <span style:"display: flex;"> 
                              <p style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
                                item?.color
                              }; border: 1px solid black;">
                              </p></span>
                              <span class="price" style="color: #000;"><strong>sh. ${
                                item?.product_id?.price
                              }</strong></span>
                            </div>
                          </div>  
                          </td>
                         
                        </tr>
                      `
                        )} 
                        <tr><td valign="middle" style="padding: 1em 2.5em;">
                        <p><a href="#" class="btn btn-primary">Total Price: ${priceWithShapping}</a></p>
                          </td></tr>
                      </table>
                    </tr>
                  </table>
                  <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
                    <tr>
                      <td valign="middle" class="bg_light footer email-section">
                        <table>
                          <tr>
                            <td valign="top" width="33.333%" style="padding-top: 20px;">
                              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align: left; padding-right: 10px;">
                                    <h3 class="heading" style="font-size: 16px;">Details</h3>
                                    <p>Sub Totals: ${totalPrice}</p>
                                    <p>Shipping: ${totalShapping}</p>
                                    <p>Total Price: ${priceWithShapping}</p>
                                    <p>Pament: ${res.data.paymentMethod}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td valign="top" width="33.333%" style="padding-top: 20px;">
                              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align: left; padding-left: 5px; padding-right: 5px;">
                                    <h3 class="heading" style="font-size: 16px;">Delivery Address</h3>
                                    <ul>
                                      <li><span class="text">${
                                        res.data.address.city
                                      }, ${res.data.address.governorate}, ${
              res.data.address.apartment
            }, ${res.data.address.building}, ${
              res.data.address.street
            }</span></li>
                                      <li><span class="text">${
                                        res.data.phone
                                      }</span></a></li>
                                    </ul>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
    
                </div>
              </center>
            </body>
            </html>
            `,
          });
        } catch (error) {
          console.log("email sending error", error);
        }

        // delete form data from localStorage
        localStorage.removeItem("localFormData");
        setButtonText("order Done");
      })
      .catch((error) => {
        console.log(error.response);
        // dispatch(showToast("Unable to make order, please try again."));
        SetShowBtnSpinner(false);
      });
  };

  const onConfirmClickButton = () => {
    setSelected(true);
    if (paymentMethod === "notselected") {
      dispatch(showToast("Please, Choose your preferred Payment Method."));
    } else {
      setShowWarning(true);
    }
  };

  const mpesaInput = () => {
    setShowMpesa(true);
    setPaymentMethod("mpesa");
  };
  const handlePrimerDeliveryChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setPrimierPayment(500);
    } else {
      setPrimierPayment(0);
    }
  };
  // const payHandler = async (event) => {
  //   event.preventDefault();
  //   setCountdown(35);
  //   setMpesaLoading(true);
  //   await axiosInstance
  //     .post("/stk", {
  //       amount: Math.floor(priceWithShapping),
  //       phone: formData.phone,
  //     })
  //     .then(async (res) => {
  //       dispatch(
  //         showToast(
  //           "STK push sent to your phone, Enter your PIN to complete transaction!"
  //         )
  //       );

  //       setTimeout(async () => {
  //         try {
  //           await axiosInstance
  //             .post("/stkquery", {
  //               CheckoutRequestID: res.data.CheckoutRequestID,
  //             })
  //             .then((res) => {
  //               console.log("stkquers res", res.data.ResultDesc);
  //               setMpesaLoading(false);
  //               if (
  //                 res.data.ResultDesc ===
  //                 "The initiator information is invalid."
  //               ) {
  //                 dispatch(showToast(`You entered wrong password!`));
  //                 setMpesaMessage("You entered wrong password!");
  //               }
  //               if (
  //                 res.data.ResultDesc ===
  //                 "The service request is processed successfully."
  //               ) {
  //                 dispatch(showToast(`Payment Received!`));
  //                 setShowMpesa(false);
  //               } else {
  //                 dispatch(showToast(`${res.data.ResultDesc}`));
  //                 setMpesaMessage(`${res.data.ResultDesc}`);
  //               }
  //             });
  //         } catch (error) {
  //           setMpesaLoading(false);
  //           dispatch(showToast("Error Occured, Please try Again!"));
  //           console.log(error);
  //         }
  //       }, 30000);
  //     })
  //     .catch((error) => {
  //       setMpesaLoading(false);
  //       dispatch(
  //         showToast("Error Ocurred, Please try Again, or use method Two Below!")
  //       );
  //       console.log(error);
  //     });
  // };

  const payheroHandler = async (event) => {
    event.preventDefault();
    setCountdown(35);
    setMpesaLoading(true);
    await axiosInstance
      .post("/payhero-stk", {
        amount: Math.floor(priceWithShapping),
        phone: formData.phone,
      })
      .then(async (res) => {
        dispatch(
          showToast(
            "STK push sent to your phone, Enter your PIN to complete transaction!"
          )
        );
        const checkPaymentStatus = async (res) => {
          const toastId = toast.loading("Verifying Payment....");
          setTimeout(async () => {
            try {
              const resp = await axiosInstance.get(
                `/payhero-check-status/${res.data.data.reference}`,
                null
              );
              setMpesaLoading(false);

              if (resp.data.data.status === "SUCCESS") {
                toast.dismiss(toastId);
                dispatch(showToast("Payment Successful. Confirm Order Now"));
                setMpesaMessage("Payment Successful");
                setShowMpesa(false);
                onConfirmClick();
              } else {
                toast.dismiss(toastId);
                console.log("status response", resp);

                toast.error("Payments was not confirmed");
                toast.custom(
                  (a) => (
                    <div
                      style={{
                        maxWidth: "24rem",
                        width: "100%",
                        backgroundColor: "white",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: "0.5rem",
                        pointerEvents: "auto",
                        display: "block",
                        flexDirection: "row",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        opacity: a.visible ? 1 : 0,
                        transition: "opacity 0.3s ease",
                      }}
                    >
                      <div style={{ flex: 1, padding: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                            <p
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: "500",
                                color: "#1F2937",
                              }}
                            >
                              {" "}
                              We Could not Verify Your Payment.
                            </p>
                            <p
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "0.875rem",
                                color: "#6B7280",
                              }}
                            >
                              {" "}
                              If Payments was deducted from your account, click
                              Re-Confirm.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "block",
                          borderTop: "1px solid rgba(229, 229, 229, 1)",
                        }}
                      >
                        {" "}
                        <button
                          onClick={() => {
                            toast.dismiss(a.id);
                            checkPaymentStatus(resp);
                          }}
                          style={{
                            width: "100%",
                            borderRight: "1px solid rgba(74, 222, 128, 1)",
                            borderRadius: "0",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                            color: "#6366F1",
                            cursor: "pointer",
                            transition: "color 0.2s ease",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.color = "#4F46E5")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.color = "#6366F1")
                          }
                        >
                          Re-Confirm
                        </button>
                        <button
                          onClick={() => {
                            toast.dismiss(a.id);
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "0",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.875rem",
                            fontWeight: "500",
                            color: "#6366F1",
                            cursor: "pointer",
                            transition: "color 0.2s ease",
                            borderTop: "1px solid rgba(229, 229, 229, 1)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.color = "#4F46E5")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.color = "#6366F1")
                          }
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ),
                  { duration: 30000 }
                );

                setMpesaMessage(
                  "Your payments was not confirmed. Please Try again."
                );
              }
            } catch (error) {
              toast.dismiss(toastId);
              setMpesaLoading(false);
              dispatch(showToast("Error Occurred, Please try Again!"));
              console.log(error);
            }
          }, 30000);
        };
        checkPaymentStatus(res);
      })
      .catch((error) => {
        setMpesaLoading(false);
        dispatch(
          showToast("Error Ocurred, Please try Again, or use method Two Below!")
        );
        console.log(error);
      });
  };

  const [countdown, setCountdown] = useState(35);

  useEffect(() => {
    let timer;
    if (mpesaLoading && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [mpesaLoading, countdown]);

  return formData ? (
    <>
      <div className={`${style.checkoutSmalContainer} row mt-5 `}>
        <div
          className={`${style.leftFormColum}  col-lg-6 col-md-12  col-12   mb-3  px-sm-0 px-md-5 `}
        >
          <div className="">
            <div className={`${style.PaymentMethod}  container `}>
              <div className="container">
                <div className="form-control mr-5 ps-4">
                  <OrderInfo formData={formData} />
                </div>
                <p className="mt-5 ms-1"> Shipping method</p>
                <div
                  className={`${style.shippingMethod}   form-control active-input mb-1`}
                >
                  <div className="row">
                    <div className="col-4 "> standard</div>
                    <div className="col-5"> </div>
                    <div className="col-2">
                      {shipping === 300 ? "300" : "Free"}
                    </div>
                  </div>
                </div>
                <div
                  className={`${style.paymentMethod} form-control d-flex mb-5`}
                  style={{ marginLeft: "1px" }}
                >
                  <div className="">
                    <div className="form-check form-check-inline d-flex align-items-center mb-3">
                      <input
                        id="delivery-checkbox"
                        className="form-check-input me-2"
                        type="checkbox"
                        name="primerDelivery"
                        value="primerDelivery"
                        onChange={handlePrimerDeliveryChange}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        className="form-check-label mt-3"
                        htmlFor="delivery-checkbox"
                        onChange={handlePrimerDeliveryChange}
                        style={{ cursor: "pointer" }}
                      >
                        Premier Delivery + sh. 500
                      </label>
                    </div>
                  </div>
                </div>
                {/* payment methods */}
                <p className="mt-4 ms-1">Payment method</p>
                <div
                  className={`${style.paymentMethod} form-control d-flex row ${
                    selected && paymentMethod === "notselected"
                      ? "border border-danger"
                      : ""
                  }`}
                  style={{ marginLeft: "1px" }}
                >
                  <div className="col-6">
                    <div className="form-check form-check-inline d-flex align-items-center mb-3">
                      <input
                        id="cash-radio"
                        className="form-check-input me-2"
                        type="radio"
                        name="paymentMethod"
                        value="cashOnDelivery"
                        onClick={() => setPaymentMethod("cash")}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        className="form-check-label mt-3"
                        htmlFor="cash-radio"
                        onClick={() => setPaymentMethod("cash")}
                        style={{ cursor: "pointer" }}
                      >
                        Cash
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check form-check-inline d-flex align-items-center">
                      <input
                        id="mpesa-radio"
                        className="form-check-input me-2"
                        type="radio"
                        name="paymentMethod"
                        value="mpesa"
                        onClick={() => mpesaInput()}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        className="form-check-label mt-3"
                        htmlFor="mpesa-radio"
                        onClick={() => mpesaInput()}
                        style={{ cursor: "pointer" }}
                      >
                        Mpesa
                      </label>
                    </div>
                  </div>
                </div>
                {/* ends here */}

                <div className="row mb-4  w-100 mx-auto  ">
                  <Link
                    to="/checkout"
                    className={` col-lg-6  col-md-6 col-sm-12  col-12  mt-2 mb-3  mt-4 ${style.returnLink} text-decoration-none `}
                  >
                    {`<  `} return to information
                  </Link>
                  {!showBtnSpinner ? (
                    <button
                      className={`${style.orderbtn} col-lg-6  col-md-6 col-sm-12  col-12  btn  h-100  ws-100 me-0 `}
                      onClick={() => {
                        onConfirmClickButton();
                      }}
                      disabled={isAddingOrder}
                    >
                      {buttonText}
                    </button>
                  ) : (
                    <button
                      className={`${style.orderbtn} col-lg-6  col-md-6 col-sm-12  col-12  btn  h-100  ws-100 me-0 `}
                      onClick={() => {
                        setShowWarning(false);
                      }}
                      disabled={isAddingOrder}
                    >
                      {buttonText}
                      <div className="spinner-border spinner-border-sm"></div>
                    </button>
                  )}{" "}
                </div>
                <hr className="border" />
                <small className={`${style.gray} mt-2`}>
                  All Rights Reserved to Ninety One team
                </small>
              </div>
            </div>

            {showWarning && (
              <ConfirmPopup
                msg={"Please Confirm You want to place order."}
                onConfirm={() => {
                  onConfirmClick();
                  setIsAddingOrder(true);
                }}
                onCancel={() => {
                  setShowWarning(false);
                  setIsAddingOrder(false);
                  window.location.reload();
                }}
              />
            )}

            {showMpesa && (
              <Modal show={showMpesa} style={{ backdropFilter: "blur(2px)" }}>
                <Modal.Header>
                  <Modal.Title>Mpesa Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Add Mpesa payment instructions and actions here */}
                  {mpesaMessage && (
                    <div class="alert alert-info" role="alert">
                      {mpesaMessage}
                    </div>
                  )}
                  <div
                    className={`${style.orderInfo} border rounded row mr-5 m-1`}
                  >
                    <div className={`${style.first} row mr-5 `}>
                      <div className={`${style.gray} col-3 mt-3`}> Phone</div>
                      <div className="col-6 mt-3"> {formData?.phone}</div>
                      <div className="col-3 mt-3">
                        <Link to="/checkout " className={`${style.linkclass} `}>
                          {" "}
                          change{" "}
                        </Link>
                      </div>
                    </div>
                    <hr className="border mb-2 mt-3" />
                    <div className={`${style.last} row mb-2`}>
                      <div className={`${style.gray} col-3`}> Amount: </div>
                      <div className="col-6">
                        <strong>{priceWithShapping.toLocaleString()}</strong>
                      </div>
                    </div>
                    <button
                      variant="success"
                      className={`btn rounded-top-0 ${
                        mpesaLoading ? "btn-secondary" : "btn-success"
                      }`}
                      // onClick={payHandler}
                      onClick={payheroHandler}
                      disabled={mpesaLoading}
                    >
                      {mpesaLoading ? (
                        <>Processing in {countdown} seconds . . .</>
                      ) : (
                        "Pay Now"
                      )}{" "}
                    </button>
                  </div>

                  <p
                    className=""
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    (**keep your payment receipt until product(s) delivery)
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowMpesa(false);
                      window.location.reload();
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
        <div
          className={`${style.rightorderColumn} col-lg-6 col-md-12 col-12 shopping-cart`}
          // style={{
          //   marginTop: "0",
          //   "@media (max-width: 768px)": {
          //     marginTop: "100px",
          //   },
          // }}
        >
          <ShoppingCardComponent
            priceWithShapping={priceWithShapping}
            primierPayment={primierPayment}
          />
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
}
