// style
import style from "../../pages/productDetails/productDetails.module.css";

const Colors = ({ colors, active, onColorChange }) => {
  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      {colors.map((ele) => (
        <div className="">
          <div
            className={`d-flex flex-wrap align-items-center border rounded p-2 ${
              ele.color === active.color
                ? "rounded border border-2 border-black"
                : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => onColorChange(ele)}
          >
            <p className="text-center mb-0" style={{ marginRight: "2px" }}>
              {ele.size}
            </p>
            <span
              key={ele.color}
              className={`${style["color-choice"]} ${
                ele.color === active.color ? style.active : ""
              } d-inline-block rounded-circle border border-2 border-white `}
              style={{ backgroundColor: ele.color, marginRight: "2px" }}
              onClick={() => onColorChange(ele)}
            ></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Colors;
