import React from "react";
import HomeProductsCard from "./homeProductsCard";

const TrendingProductCart = ({ product }) => {
  return (
    <div>
      {" "}
      <HomeProductsCard product={product} />
    </div>
  );
};

export default TrendingProductCart;
