import { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// components
import { LeftArrow, RightArrow } from "./../common/customSliderArrows";
import axiosInstance from "../../apis/config";

// style
import styles from "./../../pages/home/home.module.css";
import TrendingProductCart from "../common/trendingProductCart";

// React multi-carousel breakpoints
const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
    slidesToSlide: 5,
  },
  large: {
    breakpoint: { max: 1199, min: 1024 },
    items: 5,
    slidesToSlide: 5,
  },
  medium: {
    breakpoint: { max: 1023, min: 992 },
    items: 2,
    partialVisibilityGutter: 40,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 991, min: 481 },
    items: 2,
    partialVisibilityGutter: 40,
    slidesToSlide: 4,
  },
  extraSmall: {
    breakpoint: { max: 480, min: 375 },
    items: 2,
    partialVisibilityGutter: 50,
    slidesToSlide: 4,
  },
  extraExtraSmall: {
    breakpoint: { max: 374, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
    slidesToSlide: 4,
  },
};

const Trending = () => {
  const [product, setProduct] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    axiosInstance
      .get("/products")
      .then(async (response) => {
        const shuffledProducts = await response.data.data.sort(
          () => Math.random() - 0.5
        );
        setProduct(shuffledProducts);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className={`${styles.trendingSection} container`}>
        <h3 className="he-headers">
          <span>Top</span> Trending
        </h3>
        {showSpinner ? (
          <>
            <div class="product-grid">
              <div class="card" aria-hidden="true">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
              <div class="card" aria-hidden="true">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
              <div class="card" aria-hidden="true">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
              <div class="card" aria-hidden="true">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
              <div class="card" aria-hidden="true">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container-fluid max-h-95">
            <div className={`row justify-contant-between max-h-95`}>
              <Carousel
                responsive={responsive}
                containerClass="multi-carousel"
                draggable
                swipeable
                partialVisible
                infinite
                keyBoardControl
                minimumTouchDrag={20}
                removeArrowOnDeviceType={[
                  "medium",
                  "tablet",
                  "extraSmall",
                  "extraExtraSmall",
                ]}
                customRightArrow={<RightArrow />}
                customLeftArrow={<LeftArrow />}
                className="carousel__treading"
              >
                {product.map((product) => (
                  <div key={product._id} className="mx-1">
                    <TrendingProductCart product={product} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Trending;
