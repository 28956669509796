import React, { useEffect, useState } from "react";
import "./homeProducts.css";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart } from "../../functions/cart";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCartShopping, faPlus } from "@fortawesome/free-solid-svg-icons";
import { showCartModal } from "../../store/slices/cartModalSlice";
import { showToast } from "../../store/slices/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import StarRating from "./starRating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

const HomeProductsCard = ({ product, show, setShowPopover }) => {
  function getTotalStock(colors) {
    let totalStock = 0;
    for (const color of colors) {
      totalStock += color.stock;
    }
    return totalStock;
  }
  const [inCart, setInCart] = useState(false);
  const [showBtnSpinner, setShowBtnSpinner] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [activeColor, setActiveColor] = useState("");
  const [activeSize, setActiveSize] = useState("");

  const cart = useSelector((state) => state.cart.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = () => {
    if (cart.items) {
      setShowBtnSpinner(true);
    }
    if (product.colors?.length <= 1) {
      addItemToCart(dispatch, cart._id, product._id, activeColor, activeSize);

      // setShowPopover(true);
      // dispatch(showToast("Product Added To Cart!"));
      // dispatch(showCartModal(true));
    } else {
      navigate(`/product-details/${product._id}`);
      dispatch(showToast("Please Select Color and Size!"));
    }
  };

  useEffect(() => {
    setShowBtnSpinner(false);
    let outOfStock = false;
    if (product.colors.every((obj) => obj.stock === 0)) {
      setOutOfStock(true);
      outOfStock = true;
    }
    if (!cart.items) {
      setInCart(false);
      return;
    } else if (!outOfStock) {
      const activeColor = product.colors.find((obj) => obj.stock > 0);
      setActiveColor(activeColor.color);
      setActiveSize(activeColor.size);
    }
    const productIndx = cart.items.findIndex(
      (ele) => ele.product_id._id === product._id
    );
    setInCart(productIndx === -1 ? false : true);
  }, [cart, product]);

  const shareToSocialMedia = (value, imageUrl) => {
    if (navigator.share) {
      const shareData = {
        title: "Check out this product!",
        text: "Product Link: " + value,
        url: value,
      };

      const files = [];
      if (imageUrl) {
        fetch(imageUrl)
          .then((response) => response.arrayBuffer())
          .then((imageBuffer) => {
            const imageFile = new File([imageBuffer], "product_image.jpg", {
              type: "image/jpeg",
            });

            files.push(imageFile);
            shareData.files = files;

            navigator
              .share(shareData)
              .then(() => {
                console.log("Product link and image shared successfully!");
              })
              .catch((error) => {
                console.error("Error sharing:", error);
              });
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            navigator.share(shareData);
          });
      } else {
        navigator.share(shareData);
      }
    } else {
      copyToClipboard(value);
    }
  };
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(showToast("Link Copied!"));
  };

  const addToRecentlyViewed = (product) => {
    let recentlyViewed = localStorage.getItem("recentlyViewedProducts");
    recentlyViewed = recentlyViewed ? JSON.parse(recentlyViewed) : [];

    const productIndex = recentlyViewed.findIndex(
      (item) => item._id === product._id
    );
    if (productIndex === -1) {
      recentlyViewed.unshift(product);
      if (recentlyViewed.length > 10) {
        recentlyViewed.pop();
      }

      // Update local storage
      localStorage.setItem(
        "recentlyViewedProducts",
        JSON.stringify(recentlyViewed)
      );
    }
  };

  const [recentlyViewed, setRecentlyViewed] = useState(
    JSON.parse(localStorage.getItem("recentlyViewedProducts")) || []
  );

  const removeFromRecentlyViewed = (productId) => {
    const updatedRecentlyViewed = recentlyViewed.filter(
      (product) => product._id !== productId
    );
    setRecentlyViewed(updatedRecentlyViewed);
    localStorage.setItem(
      "recentlyViewedProducts",
      JSON.stringify(updatedRecentlyViewed)
    );
    window.location.reload();
  };

  return (
    <div class="showcase">
      <div class="showcase-banner">
        <Link
          to={`/product-details/${product._id}`}
          onClick={() => addToRecentlyViewed(product)}
        >
          <img
            src={product.documents[0]?.url}
            alt="Mens Winter Leathers Jackets"
            width="300"
            class="product-img default"
            style={{ maxHeight: "200px" }}
          />
          <img
            src={
              product?.documents[1]
                ? product?.documents[1]?.url
                : product?.documents[0]?.url
            }
            alt="Mens Winter Leathers Jackets"
            width="300"
            class="product-img hover"
            style={{ maxHeight: "200px" }}
          />
        </Link>
        {/* <p class="showcase-badge angle pink">new</p> */}

        <p class="showcase-badge">-{product.discount}%</p>

        <div class="showcase-actions">
          {/* <button class="btn-action">
            <ion-icon name="heart-outline"></ion-icon>
          </button> */}

          <Link to={`/product-details/${product._id}`} class="btn-action">
            <ion-icon name="eye-outline"></ion-icon>
          </Link>
          <button
            class="btn-action"
            onClick={() =>
              shareToSocialMedia(
                `${product.name},${`/product-details/${product._id}`}`,
                product.documents && product.documents[0]?.url
              )
            }
          >
            <ion-icon name="share-outline"></ion-icon>
          </button>
          {!(outOfStock || cart.role === "admin") ? (
            showBtnSpinner ? (
              <button className="btn-action">
                <div className="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            ) : inCart ? (
              <button
                class="btn-action"
                onClick={() => dispatch(showCartModal(true))}
              >
                <ion-icon name="cart"></ion-icon>
              </button>
            ) : (
              <button class="btn-action" onClick={() => handleAddToCart()}>
                <ion-icon name="cart-outline"></ion-icon>
              </button>
            )
          ) : (
            ""
          )}
          {show && (
            <button
              class="btn-action"
              onClick={() => removeFromRecentlyViewed(product._id)}
            >
              <FontAwesomeIcon icon={faTrashCan} type="button" />{" "}
            </button>
          )}
        </div>
      </div>

      <div class="showcase-content">
        <Link to={`/product-details/${product._id}`} class="showcase-category">
          {getTotalStock(product.colors)} items
        </Link>

        <Link to={`/product-details/${product._id}`}>
          <h3 class="showcase-title">{product.name.slice(0, 30)}...</h3>
        </Link>

        <div class="showcase-rating">
          <StarRating rating={product.ratings} />
        </div>

        <div class="price-box">
          <p class="price">sh. {product.price.toLocaleString()}</p>
          <del>
            was{" "}
            {(
              (product.discount / 100) * product.price +
              product.price
            ).toLocaleString()}
          </del>
        </div>
      </div>
    </div>
  );
};

export default HomeProductsCard;
